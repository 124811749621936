import Utils, { findReportByTeam } from "@trinity-incyte/utils";
import ConfigContext from "libs/context/src/lib/config";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    activeTeam as activeTeamAtom,
    IncyteTeam,
    QlikUser as QlikUserAtom,
    dismissedAlerts as dismissedAlertsAtom,
    dismissedAlertsReasons as dismissedAlertsReasonsAtom,
    QSAppMetadataFamily,
} from '@trinity-incyte/recoil'
import { useBackend, useSelection } from "@trinity-incyte/hooks";
import { MosaicGlobal, QlikVisualizationInstance, QlikVisualizationInstanceFacade } from "@trinity-incyte/api-interfaces";


declare const Mosaic: MosaicGlobal

const addDefaultAlertTypes = (defaultAlertTypes) => defaultAlertTypes.reduce((acc, type) => { 
    acc[type] = {count: 0, name: type, indications: {}} 
    return acc
}, {});

const useMashup = (appId, mashupName, cacheKey) => {
	const app = Mosaic.Qlik.app[appId]
    const metadata = useRecoilValue(QSAppMetadataFamily(appId))
    const { IDsTable: ids } = metadata
	const mashupId = ids.get(mashupName)?.id;
	const [qVis, setQVis] = useState<QlikVisualizationInstance>()

	const onDataHandler = (visualization: QlikVisualizationInstance) => {
		setQVis(new QlikVisualizationInstanceFacade(visualization._instance))
	}

	const initializeTable = async () => {
		const visualization = await app?.visualization.get(mashupId)
		const wrappedOnDataHandler = () => {onDataHandler(visualization)}
		visualization.table.addOnDataListener(wrappedOnDataHandler)
		visualization.table._instance.getMoreData = Utils.generateGetMoreData()
		setQVis(visualization)
	}

	useEffect(() => {
		initializeTable()
		return () => {
			qVis?.close?.()
		}
	}, [cacheKey])
    
    return qVis
}

export type AlertsState = {
    counts: any,
    countTotals: any,
    events: any
}

export type AlertsContext = {
    state: AlertsState
}

export const AlertsContext = createContext({} as AlertsContext)

export const AlertsState = (props) => {
    const Config = useContext(ConfigContext)
    const fieldSalesAppId = Config.Qlik.FieldSales.ids.appId
    const app = Mosaic.Qlik.app[fieldSalesAppId]
	const activeTeam = useRecoilValue<IncyteTeam>(activeTeamAtom)
    const metadata = useRecoilValue(QSAppMetadataFamily(fieldSalesAppId))
    const { IDsTable: ids, FieldsTable: fields } = metadata
    const userData = useRecoilValue(QlikUserAtom)

	const [ dismissedAlertsReasons, setDismissedAlertsReasons ]  = useRecoilState(dismissedAlertsReasonsAtom)
	const setDismissedAlerts = useSetRecoilState(dismissedAlertsAtom)

    let alertTableFriendlyName = findReportByTeam(Config.App.reports, activeTeam, 'landingalert')?.friendlyName

    const selections = useSelection({app: app, fieldIds: props.dependencyFields})
    var cacheKey = ""
    selections.forEach((selection, id) => {
        if (selection?.qSelected != null) {
            cacheKey += `${btoa(id)}_${btoa(selection?.qSelected)};`
        }
    })

    const alertsViz = useMashup(fieldSalesAppId, alertTableFriendlyName, cacheKey)

    const extractAlertsData = () => {
        const rowCount = alertsViz?.table?.rowCount
        if (alertsViz?.table?.rows.length == null || alertsViz?.table?.rows.length == 0) {
            if (alertsViz?.table?.rows.length < rowCount) alertsViz?.table?.getMoreData()
            return {
                events: {All: rowCount == 0 ? [] : null},
                counts: {},
                countTotals: { count: 0, types: addDefaultAlertTypes(Config.App.defaultAlertTypes || []) }
            }
        }

        const tempEvents = { All: [] }
        const rows = alertsViz.table?.rows ?? []
        const { length } = rows

        //Request more rows ASAP
        if (length !== rowCount) {
            alertsViz.table.getMoreData()
            return {
                events: {All: null},
                counts: [{count: length, name: "LOADED", types: [{count: length, name: "LOADED", indications: {}}]}, {count: rowCount, name: "OUT OF", types: [{count: rowCount, name: "OUT OF", indications: {}}]}],
                countTotals: { count: length, types: [{count: rowCount, name: "OUT OF"}, {count: length, name: "LOADED"}] }
            }
        }

        const tempCounts = {}
        const countTotals = { count: 0, types: addDefaultAlertTypes(Config.App.defaultAlertTypes || []) }

        for (let ii = 0; ii < length; ii += 1) {   
            const [
                { qText: brandName },
                { qText: alertType },
                { qText: alertSubtype },
                { qText: prof },
                { qText: eventDate },
                { qText: alertTrigger },
                { qText: employee },
                { qText: supplementalLabel },
                { qText: followupFlag },
                { qText: alertKey },
                { qText: recentAlertFlag },
                { qText: alertGroup },
                { qText: alertIndication },
                { qText: supplementalTriggerText },
                { qNum: count },
            ] = rows[ii].cells

            const labels = [
                {
                    content: alertSubtype,
                    style: { color: 'var(--brand)', fontWeight: 'bold', fontSize: '1rem' },
                }, {
                    content: `Received: ${Utils.moment(eventDate).format('MM-DD-YYYY')}`,
                    style: { color: '#595959', fontWeight: 'bold', fontSize: '1rem' }
                }
            ]

            if (followupFlag !== '-') {
                labels.push({
                    content: followupFlag,
                    style: { color: '#595959', fontWeight: 'bold', fontSize: '1rem' }
                })
            }

            const event = {
                labels,
                date: Utils.moment(new Date(eventDate)),
                prof,
                profId: prof.split('(')[1].split(')')[0],
                brandName,
                alertGroup,
                alertIndication,
                alertType,
                alertSubtype,
                alertTrigger,
                employee,
                supplementalLabel,
                supplementalTriggerText,
                followupFlag,
                alertKey,
                count,
                showItems: false,
                recentAlertFlag,
                setShowItems: (val) => {
                    event.showItems = val
                },
                showActions: false,
                setShowActions: (val) => {
                    event.showActions = val
                },
            }

            tempEvents.All.push(event)

            tempCounts[alertGroup] = tempCounts[alertGroup] || {count: 0, name: alertGroup, types: addDefaultAlertTypes(Config.App.defaultAlertTypes || [])}
            tempCounts[alertGroup].count += 1
            tempCounts[alertGroup].types[alertType] = tempCounts[alertGroup].types[alertType] || {count: 0, name: alertType, indications: {}}
            tempCounts[alertGroup].types[alertType].count += 1
            tempCounts[alertGroup].types[alertType].indications[alertIndication] = tempCounts[alertGroup].types[alertType].indications[alertIndication] || {count: 0, name: alertIndication}
            tempCounts[alertGroup].types[alertType].indications[alertIndication].count += 1

            countTotals.count += 1
            countTotals.types[alertType] = countTotals.types[alertType] || {count: 0, name: alertType, indications: {}}
            countTotals.types[alertType].count += 1
            countTotals.types[alertType].indications[alertIndication] = countTotals.types[alertType].indications[alertIndication] || {count: 0, name: alertIndication}
            countTotals.types[alertType].indications[alertIndication].count += 1
        }

        return {
            events: tempEvents,
            counts: tempCounts,
            countTotals: countTotals
        }
    }

    const {events, counts, countTotals} = extractAlertsData()

    
    useMemo(() => {
        if (dismissedAlertsReasons.length == 0) {
            // Populate the alerts dismissal reasons
            let dismissalReasonsTable = findReportByTeam(Config.App.reports, activeTeam, 'dismissalReasons')?.friendlyName
            app?.visualization.get(ids.get(dismissalReasonsTable)?.id).then((qViz) => {
                const onQVizData = () => {
                    if (!qViz) return
                    const rows = qViz.table?.rows ?? []
                    const rowCount = qViz.table?.rowCount
                    const { length } = rows;

                    if (length !== rowCount) {
                        qViz.table.getMoreData(250)
                    } else {
                        const dismissalReasons = []
                        rows.forEach((item) => {
                            const { qText: id } =
                                item.cells.find(
                                    ({ qDimensionInfo: { qFallbackTitle } }) =>
                                        qFallbackTitle === 'ALERT.DISMISSAL_REASON_ID'
                                ) || {}
                            const { qText: description } =
                                item.cells.find(
                                    ({ qDimensionInfo: { qFallbackTitle } }) =>
                                        qFallbackTitle === 'ALERT.DISMISSAL_REASON_DESC'
                                ) || {}
                            dismissalReasons.push(
                                {
                                    'id': id,
                                    'description': description
                                }
                            )
                        })

                        // Set the atom here which is consumed in the dismiss-cell
                        setDismissedAlertsReasons(dismissalReasons)
                        qViz.close()
                    }
                }

                qViz.table.addOnDataListener(onQVizData)
                qViz.table._instance.getMoreData = Utils.generateGetMoreData()
                qViz.table.getMoreData(250)
            })
                .catch((err) => {
                })
        }
    }, [])


    // read from parse here
    const BACKEND_CLASS_NAME = 'AlertsDismissals'
    const Parse = useBackend()
    
    // create a connection to the parse server
    useMemo(() => {
        if (!userData) return
            // read the list of dismissed alerts from the parse database
            let filterDate = new Date()
            filterDate.setMonth(filterDate.getMonth() - 7) // oldest, inform alerts, is 6 months by rule

            const LBClass = Parse.Object.extend(BACKEND_CLASS_NAME)
            const query = new Parse.Query(LBClass)
            query.equalTo('AppName', Config.App.name)
            query.equalTo('QlikUserId', userData.authenticatedUser)
            query.equalTo('UndismissedDate', undefined)
            query.greaterThan('DismissedDate', filterDate)
            query.descending('DismissedDate')
            query.limit(500)
            query.find().then(
                (results) => {
                // map the array into an array of ids
                let dismissedAlertKeyObj = results.map(r => ({ 
                        AlertParseDbId : r.id,
                        AlertKey : r.attributes.AlertKey, 
                        AlertType : r.attributes.AlertType        
                    })
                )
                setDismissedAlerts(dismissedAlertKeyObj)
                },
                (error) => {
                    console.error('Error while fetching ParseObjects', error)
                }
            )
        }, [userData])

    return (
        <AlertsContext.Provider value={{
            state: {
                counts,
                countTotals,
                events
            },
        }}>
            {props.children}
        </AlertsContext.Provider>
    )
}

export default AlertsContext