import React, { useEffect, useState, useContext } from 'react';
import { useTransition, animated } from 'react-spring';
import { Select, Row, Dropdown, Button, Menu, Typography, Space, Divider, Layout } from 'antd';
import { DownOutlined, CheckCircleFilled } from '@ant-design/icons';
import DateSelectionButtons from '../Groups/DateSelectionButtons';
import { QSMashupObject } from '@trinity-incyte/ui';
import { useQSListbox, useQSGeneric } from '@trinity-incyte/hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	closeModalSelector,
	openModalSelector,
	QlikUser as QlikUserAtom,
	QSAppMetadataFamily,
	QlikLastModified as QlikLastModifiedAtom,
	regionSelectionCount as regionSelectionCountAtom,
	activeTeam as activeTeamAtom,
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import './RegionAnalysis.scss'
import { getTeamBrands } from '@trinity-incyte/utils'
import { ExportHelper, ExportType } from 'libs/ui/src/lib/Qlik/qsexport-helper';

declare const Mosaic: MosaicGlobal;
declare var window: any;
const { Sider } = Layout;
const size = (window.innerHeight < 800) ? 'small' : 'middle';

const MultiFilter = ({ config, fieldName, displayName }) => {
	const Config = useContext(ConfigContext);
	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: displayName,
	});

	const filterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
			return acc;
		}, []),
		options: listbox.map((val, ind) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number[]) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
				return acc;
			}, []);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);
				cell.unselect();
			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);

				let newListBoxes = []; // array for qlik
				listbox.forEach((listBoxItem) => {
					if ((listBoxItem.qElemNumber === changedCellElem[0]) || (listBoxItem.qState == Config.Qlik.qStateValues.SELECTED)) {
						newListBoxes.push({ qText: listBoxItem.qText });
					}
				});
				const appId = config.ids.appId;
				Mosaic.Qlik.app[appId].field(fieldName).selectValues(newListBoxes);
			}
		},
		placeholder: `Select ${displayName}...`,
		maxTagCount: 'responsive' as const,
	};

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label'>{`${displayName}`}</div>
				<div className='filter-reset'>
					<Button type="link"  onClick={() => { listbox[0].clear() }}>Reset</Button>
				</div>
			</div>
			<Row gutter={[2, 16]}>
				<Select {...filterProps} />
			</Row>
		</>
	);

	return content;
};

const RegionFilter = ({ config, fieldName }) => {
	const Config = useContext(ConfigContext);
	const set_regionSelectionCount = useSetRecoilState(regionSelectionCountAtom);

	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'Region',
	});

	const filterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
			return acc;
		}, []),
		options: listbox.map((val, ind) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number[]) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
				return acc;
			}, []);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);
				cell.unselect();
			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				let newListBoxes = []; // array for qlik
				listbox.forEach((listBoxItem) => {
					if ((listBoxItem.qElemNumber === changedCellElem[0]) || (listBoxItem.qState == Config.Qlik.qStateValues.SELECTED)) {
						newListBoxes.push({ qText: listBoxItem.qText });
					}
				});
				const appId = config.ids.appId;
				Mosaic.Qlik.app[appId].field(fieldName).selectValues(newListBoxes);
			}
		},
		placeholder: 'Select Region...',
		maxTagCount: 'responsive' as const,
	};

	set_regionSelectionCount(filterProps.value.length);

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label filter-label-noreset'>Region</div>
			</div>
			<Row gutter={[2, 16]}>
				<Select {...filterProps} />
			</Row>
		</>
	);

	return content;
};

const TerritoryFilter = ({ config, fieldName }) => {
	const Config = useContext(ConfigContext);
	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'Territory',
	});

	const filterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
			return acc;
		}, []),
		options: listbox.map((val, ind) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number[]) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
				return acc;
			}, []);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);
				cell.unselect();
			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);

				let newListBoxes = []; // array for qlik
				listbox.forEach((listBoxItem) => {
					if ((listBoxItem.qElemNumber === changedCellElem[0]) || (listBoxItem.qState == Config.Qlik.qStateValues.SELECTED)) {
						newListBoxes.push({ qText: listBoxItem.qText });
					}
				});
				const appId = config.ids.appId;
				Mosaic.Qlik.app[appId].field(fieldName).selectValues(newListBoxes);
			}
		},
		placeholder: 'Select Territory...',
		maxTagCount: 'responsive' as const,
	};

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label'>Territory</div>
				<div className='filter-reset'>
					<Button type="link"  onClick={() => { listbox[0].clear() }}>Reset</Button>
				</div>
			</div>
			<Row gutter={[2, 16]}>
				<Select {...filterProps} />
			</Row>
		</>
	);

	return content;
};

const BarriersAnalysis = (props) => {
	const { config } = props;
	const Config = useContext(ConfigContext);
	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const activeTeam = useRecoilValue(activeTeamAtom);
	const regionSelectionCount = useRecoilValue(regionSelectionCountAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [barrierAnalysisTables, set_barrierAnalysisTables] = useState([]);
	const openModal = useSetRecoilState(openModalSelector);
	const closeModal = useSetRecoilState(closeModalSelector);
	const [showFilters, set_showFilters] = useState<boolean>(true);
	const [collapsed, set_collapsed] = useState(false);
	const defaultActiveChart = 0;
	const [activeChart, set_activeChart] = useState<number>(defaultActiveChart);
	const [activeBrand, set_activeBrand] = useState<number>(0);
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;

	const transitions = useTransition(showFilters, null, {
		from: { position: 'relative', opacity: 0.5 },
		enter: { opacity: 1 },
		leave: { opacity: 0.5 },
	});
	const fieldNameGeneric = useQSGeneric({
		definition: {
			qInfo: {qId: `barriersAnalysis_${Date.now()}`},
			region: {
				qStringExpression:
					`=IF(FSD.TeamID=11, 'DSF.TM11.DIST_NAME_WITH_MGR'
				, IF(FSD.TeamID=12, 'DSF.TM12.DIST_NAME_WITH_MGR'
				, IF(FSD.TeamID=21, 'DSF.TM21.DIST_NAME_WITH_MGR'
				)))`
			},
			territory: {
				qStringExpression:
					`=IF(FSD.TeamID=11, 'DSF.TM11.TERR_NAME_WITH_REP'
				, IF(FSD.TeamID=12, 'DSF.TM12.TERR_NAME_WITH_REP'
				, IF(FSD.TeamID=21, 'DSF.TM21.TERR_NAME_WITH_REP'
				)))`
			},
		},
		app
	});

	const [fieldNameRegion, set_fieldNameRegion] = useState(null);
	const [fieldNameTerritory, set_fieldNameTerritory] = useState(null);
	const checkAccessToNation = () => {
		const {
			REGIONANALYSIS: { showNation } = {
				showNation: 'N',
			},
		} = QlikUser?.features || {};
		return (showNation === 'Y');
	};
	const checkAccessToRegion = () => {
		const {
			REGIONANALYSIS: { showRegion } = {
				showRegion: 'N',
			},
		} = QlikUser?.features || {};
		return (showRegion === 'Y');
	};
	const [isOneRegion, set_isOneRegion] = useState<boolean>(!checkAccessToNation());

	useEffect(() => {
		if (!checkAccessToNation()) return;

		const varName = 'isOneRegion';
		const getVariable = () => {
			app.variable.getByName(varName).then(function (model) {
				model.getLayout().then(layout => {
					set_isOneRegion((layout.qText) || (regionSelectionCount === 1) ? true : false);
				})
			}, function (errorObject) {
				if (errorObject?.error?.code === 2) {
					app.variable
						.createSessionVariable({ qName: varName, qDefinition: `=ONLY([${fields.get('Mosaic_Field Sales_Region HO1')?.id}])` })
						.then(() => {
							getVariable();
						});
				}
			});
		};

		getVariable();
	}, [QlikLastModified, regionSelectionCount]);

	useEffect(() => {
		const locationField = app.field('LocationSelectionDesc');
		if (isOneRegion) {
			locationField.selectValues(['Territory']);
		} else {
			locationField.selectValues(['Region']);
		}
	}, [isOneRegion, activeTeam]);

	useEffect(() => {
		if (!fieldNameGeneric) return;
		set_fieldNameRegion(fieldNameGeneric.region);
		set_fieldNameTerritory(fieldNameGeneric.territory);
	}, [JSON.stringify(fieldNameGeneric)]);

	let tables;

	useEffect(() => {

		tables = Config.App.reports.fieldSalesBarriersAnalysis.map((fieldSalesObject, index) => {
			const {id, description} = ids.get(fieldSalesObject.friendlyName);
			return {
				id,
				key: index.toString(),
				label: description,	
				dataFormat: fieldSalesObject.dataFormat
			};
        });

		if (activeChart > tables.length) {
			set_activeChart(0); // reset the menu to the first item if the list changes
		}

		set_barrierAnalysisTables(tables);

	}, [activeTeam, activeBrand]);

	useEffect(() => {
		set_activeBrand(0);
	}, [activeTeam])

	const filterFields = {
		area: 'DSF.TM11.AREA_NAME_WITH_MGR',
		indication: 'BARR.INDICATION',
		source: 'BARR.SOURCE_CATEGORY',
		region: fieldNameRegion,
		territory: fieldNameTerritory,
	};

	function handleChange(value) {
		set_activeChart(parseInt(value.key, 10));
	}

	function handleBrandChange(value) {
		set_activeBrand(parseInt(value.key, 10));
	}

	interface SlidesProps {
		id: string;
		label?: string;
	}

	const onClickExportPPT = () => {
		let barrierAnalysisTablesToExport = barrierAnalysisTables.filter((tablesToFilter) => tablesToFilter.dataFormat!=="table");
		const exportHelper = ExportHelper(Config, Mosaic.Qlik.app[appId], barrierAnalysisTablesToExport.map(tc => tc.id), openModal, closeModal, "BarriersAnalysis")
		exportHelper.exportVisualization(ExportType.exportAsPPT)
	};

	const menu = (
		<Menu onClick={handleChange}>
			{barrierAnalysisTables.map(({ label }, ind) => (
				<Menu.Item key={ind} data-label={label}>
					{(ind === activeChart) && (
						<CheckCircleFilled style={{ color: 'var(--brand)', marginRight: '4px' }} />
					)}
					{label}
				</Menu.Item>
			))}
		</Menu>
	);

	var brands = getTeamBrands(Config.App.teamToBrand, activeTeam);
	const brandMenu = (
		<Menu onClick={handleBrandChange}>
			{brands.map((brand, ind) => (
				<Menu.Item key={ind} data-label={brand}>
					{(ind === activeBrand) && (
						<CheckCircleFilled style={{ color: 'var(--brand)', marginRight: '4px' }} />
					)}
					{brand}
				</Menu.Item>
			))}
		</Menu>
	);

	let cardContent;
	
	const colOverrides = {
		"HCP Barrier History" : [
			{
			  columns: [1],
			  hStyles: { width: "10rem" },
			},
			{
			  columns: [2],
			  hStyles: { width: "9rem" },
			},
			{
			  columns: [3, 4],
			  hStyles: { width: "6rem" },
			},
			{
			  columns: [5, 6, 7, 8],
			  hStyles: { width: "9rem" },
			},
			{
			  columns: [9],
			  hStyles: { width: "10rem" },
			},
			{
			  columns: [10],
			  hStyles: { width: "13rem" },
			},
			{
			  columns: [11],
			  hStyles: { width: "10rem" },
			},
			{
			  columns: [12],
			  hStyles: { width: "15rem" },
			},
		  ]		
	}
	if (barrierAnalysisTables.length > 0) {
		let isTable = barrierAnalysisTables[activeChart].dataFormat=="table";
		cardContent = (
			<QSMashupObject
				appId={appId}
				mashupId={barrierAnalysisTables[activeChart]?.id}
				key={`comparison_chart_${barrierAnalysisTables[activeChart]?.id}`}
				isTable={isTable}
				showExports={(true)}
				tableProps={{
					colOverrides: colOverrides[barrierAnalysisTables[activeChart].label] || []
				  }}
				dynamic
				exportAsPPT={!isTable}
				exportAsImg={!isTable}
				addon={!isTable && ( // No-op div to disable user interaction
					<div
						style={{
							height: '94%',
							width: 'inherit',
							zIndex: 101,
							position: 'absolute',
							top: '6%', // Allows exports to function
							left: 0
						}}
					/>
				)}
			/>
		);
	}
	

	const filtersList = (
		transitions.map(({ item, key, props }) => (
			item && (
				<animated.div key={key} style={props}>
					<div style={{maxWidth:'98%'}}>
						<div style={{minWidth:'100%', textAlign:'right'}}>
							<Button type="text" onClick={onClickExportPPT}>Download All</Button>
						</div>
						<div className="filter-label-container" style={{margin: '0px 0px 4px 0px'}}>
							<div className='filter-label filter-label-noreset'>Chart</div>
						</div>
						<Row gutter={[2, 16]}>
							<Space size="small" direction="vertical" style={{ width: '98%', margin:'0px 0px 0px 4px' }}>
								<Dropdown
									trigger={['click']}
									overlay={menu}
									placement="bottomRight"
								>
									<Button
										style={{ width: '100%', fontWeight: 'normal' }}
										size={size}
									>
										<DownOutlined style={{
											float: 'right',
											marginTop: '4px',
											color: 'var(--brand)',
										}} />
										<div
											title={barrierAnalysisTables.length > 0 && barrierAnalysisTables[activeChart].label}
											style={{
												paddingRight: '4%',
												paddingLeft: '0%',
												marginLeft: '4px',
												width: '90%',
												overflow: 'hidden',
												textAlign: 'left',
												fontWeight: 'bold',
												textDecorationColor: 'var(--brand)',
											}}
										>
											{barrierAnalysisTables.length > 0 && barrierAnalysisTables[activeChart].label}
										</div>
									</Button>
								</Dropdown>
							</Space>
						</Row>
						<div style={{height:'6px'}}></div>
						<MultiFilter config={config} fieldName={filterFields.indication} displayName={"Indication"} />

						<MultiFilter config={config} fieldName={filterFields.source} displayName={"Source"} />
						{checkAccessToNation() && (
							<RegionFilter config={config} fieldName={filterFields.region} />
						)}
						{((checkAccessToNation() || checkAccessToRegion())) && (
							<TerritoryFilter config={config} fieldName={filterFields.territory} />
						)}
						<div className="filter-label-container" style={{margin: '0px 0px 4px 0px'}}>
							<div className='filter-label filter-label-noreset'>Date</div>
						</div>
						<Row gutter={[2, 16]}>
							<DateSelectionButtons layout="BARRIERANALYSIS" config={config} size={size} />
						</Row>
					</div>
				</animated.div>
			)))
	);

	return (
		<Layout hasSider style={{ height: '100%', transform: 'scale(1.0)' }}>
			<div className='content-row'>
				<Sider
					width={310} collapsible theme="light"
					onCollapse={(collapsed) => {
						set_collapsed(collapsed);
					}}
					style={{ height: 'calc(100% - 4rem)', overflow: 'auto', padding: '10px' }}
				>
					{collapsed === false && (
						<>
							{filtersList}
						</>
					)}
				</Sider>
				<Layout style={{ backgroundColor: 'white', minWidth: '0' }} >
					{cardContent}
				</Layout>
			</div>
		</Layout>
	);
}

export default BarriersAnalysis;
