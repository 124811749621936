import React from 'react';
import { useRecoilValue } from 'recoil';
import { BackTop } from 'antd';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { RGLGrid as Grid, NavGroupMosaic, SubPage, LeftSlider } from '@trinity-incyte/ui';
import { activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';

const MCE = (props) => {
    const { profId } = props;
    const urlSuffix = profId ? `/${profId}` : '';
    const activeTeam = useRecoilValue(activeTeamAtom);
    const isProfSelected = use360ProfSelection(profId);
    let defaultLayout;

    if (profId === false) {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Message Box',
                    props: {
                        color: 'green',
                        text: 'Connections',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId === '-') {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Message Box',
                    props: {
                        color: 'orange',
                        text: 'Please select one HCP to view the overview.',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId !== false && !isProfSelected) {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Loader',
                    props: {
                        color: 'blue',
                        text: 'Loading...',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId !== false && isProfSelected) {
        if (activeTeam) {
            defaultLayout = {
                responsive: [
                    {
                        w: 5,
                        i: 'Mosaic Overview Prof Box',
                        props: { profId },
                    },
                    {
                        w: 19,
                        style: {backgroundColor: 'white'},
                        items: [
                            {
                                items: [
                                    {
                                        w: 6,
                                        i: 'MCE Channel Header',
                                    },
                                    {
                                        w: 9,
                                        i: 'MCE Benchmarks (90)',
                                    },
                                    {
                                        w: 9,
                                        i: 'MCE Rolling Months',
                                    },
                                ]
                            },
                            {
                                items: [
                                    {
                                        w: 6,
                                        i: 'Face To Face IA MCE KPI BOX',
                                    },
                                    {
                                        w: 9,
                                        i: 'Face to Face Benchmarks KPI Box',
                                        props: { profId },
                                    },
                                    {
                                        w: 9,
                                        i: 'Face to Face Jakafi/Pemi - Monjuvi Digital Benchmark',
                                        props: { profId },
                                    },
                                ]
                            },
                            {
                                items: [
                                    {
                                        w: 6,
                                        i: 'Peer Interaction IA MCE KPI BOX',
                                    },
                                    {
                                        w: 9,
                                        i: 'Peer Interaction Speaker Program Box',
                                        props: { profId },
                                    },
                                    {
                                        w: 9,
                                        i: 'Speaker Program Jakafi/Pemi - Monjuvi KPI Box',
                                        props: { profId },
                                    },
                                ]
                            },
                            {
                                items: [
                                    {
                                        w: 6,
                                        i: 'Digital Push IA MCE KPI BOX',
                                    },
                                    {
                                        w: 9,
                                        i: 'Digital Push Jakafi/Pemi - Monjuvi Box',
                                        props: { profId },
                                    },
                                    {
                                        w: 9,
                                        i: 'Digital Push Jakafi/Pemi - Monjuvi KPI Box',
                                        props: { profId },
                                    },
                                ]
                            },
                            {
                                items: [
                                    {
                                        w: 6,
                                        i: 'Digital Pull IA MCE KPI BOX',
                                    },
                                    {
                                        w: 9,
                                        i: 'Digital Pull Jakafi/Pemi - Monjuvi Box',
                                        props: { profId },
                                    },
                                    {
                                        w: 9,
                                        i: 'Digital Pull Jakafi/Pemi - Monjuvi KPI Box',
                                        props: { profId },
                                    },
                                ]
                            },
                        ]
                    },
                ]
            };
        } else {
            defaultLayout = {
                xs: [
                    {
                        w: 5,
                        h: 16,
                        x: 0,
                        y: 0,
                        i: 'Mosaic Overview Prof Box',
                        props: {
                            profId: profId,
                        },
                        moved: false,
                        static: true,
                    }
                ],
            };
        }
    }

    const content = (
        <>
            <NavGroupMosaic urlSuffix={urlSuffix} />
            <Grid
                title="MCE"
                className="mosaicOverviewLayout gridContentLayout"
                layout={defaultLayout}
                gutter={[0,0]}
                containerPadding={[0,0]}
            />
            <LeftSlider />
        </>
    );

    return content;
};

export default MCE;
