import React, { useContext, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { useParams, useHistory } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { QSMashupObject } from '@trinity-incyte/ui';
import { ConfigContext } from '@trinity-incyte/context';
import { 
	activeTeam as activeTeamAtom,
	QSAppMetadataFamily,
 } from '@trinity-incyte/recoil';
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";

const { Sider } = Layout;

declare const window: any;
declare const Mosaic: MosaicGlobal;

const getDetailsTables = (selectedTeam, selectedBrand) => {	
	const Config = useContext(ConfigContext);
	const { appId } = Config.Qlik.FieldSales.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids } = metadata;

	let detailsOptions = Config.App.reports.details.filter(({types, teams, brand, friendlyName}, index) => {
		if (ids.get(friendlyName)==undefined) {
			console.log(`Error: Missing Object: ${friendlyName}`);
		} else if (teams.includes(selectedTeam) && brand === selectedBrand.toUpperCase()) {
			return {types, teams, brand};
		}
	});

	let detailsTables = detailsOptions.map((option, index) => {
		const {id, description, tableProps} = ids.get(option.friendlyName);
		return {
			id,
			tableProps,
			key: index.toString(),
			chartName: description
		};
	});


	return detailsTables;
};


const DetailsPane = (props) => {
	const history = useHistory() as any;
	let { subTab } = useParams() as any;
	const activeTeam = useRecoilValue(activeTeamAtom)
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;
	const { layout } = props;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;

	const clearSelections = () => {
		const app = Mosaic.Qlik.app[appId];

		const selectionsFilter = config.locks.map(
			(fieldName) => fields.get(fieldName)?.id
		);

		app.selections?.forEach((selection) => {
			if (!selectionsFilter.includes(selection.fieldName)) {
				app.field(selection.fieldName).clear();
			}
		});
	};

	useEffect(() => {
		clearSelections();
	}, []);

	let tables = getDetailsTables(activeTeam, layout).reduce((acc, curr) => {
		acc[curr.chartName] = curr.id;
		return acc
	}, {});

	subTab = decodeURIComponent(subTab);
	let chartName = subTab.replace(/Percent/g, '%');

	// This needs to get refactored to be less clunky
	const tableProps: any = { lazyLoadRows: true, rowLimit: 1000 };
	switch (chartName) {
		case "Top 10 Prescribers":
		case "Top 10 Gainers":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "16rem" },
				},
				{
					columns: [1, 2, 3, 4, 5],
					hStyles: { width: "9rem" },
				},
			];
			break;
		case "SP Bottles":
			tableProps.colOverrides = [
				{
					columns: [1],
					hStyles: { width: "16rem" },
				},
				{
					columns: [2],
					hStyles: { width: "14rem" },
				},
				{
					columns: [3],
					hStyles: { width: "8rem" },
				},
				{
					columns: [4],
					hStyles: { width: "4rem" },
				},
				{
					columns: [5],
					hStyles: { width: "5rem" },
				},
				{
					columns: [6, 7, 8, 9],
					hStyles: { width: "4rem" },
				},
			];
			break;
		case "Alerts":
			if (layout.toUpperCase() === "PEMAZYRE") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1],
						hStyles: { width: '14rem' },
					},
					{
						columns: [2],
						hStyles: { width: '9rem' },
					},
					{
						columns: [3],
						hStyles: { width: '6rem' },
					},
					{
						columns: [4],
						hStyles: { width: '6rem' },
					},
					{
						columns: [5],
						hStyles: { width: '10rem' },
					},
					{
						columns: [6],
						hStyles: { width: '10rem' },
					},
					{
						columns: [7],
						hStyles: { width: '20rem' },
					},
					{
						columns: [8, 9],
						hStyles: { width: '10rem' },
					},
					{
						columns: [10],
						hStyles: { width: '8rem' },
					},
					{
						columns: [11, 12, 13],
						hStyles: { width: '10rem' },
					},
					{
						columns: [14, 15],
						hStyles: { width: '8rem'},
					},
					{
						columns: [16],
						hStyles: { width: '10rem' },
					},
					{
						columns: [17],
						hStyles: { width: '8rem' },
					},
					{
						columns: [18],
						hStyles: { width: '20rem' },
					},
				];
			}
			if (layout.toUpperCase() === "MONJUVI") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1],
						hStyles: { width: '12rem'},
					},
					{
						columns: [2],
						hStyles: { width: '9rem' },
					},
					{
						columns: [3],
						hStyles: { width: '6rem' },
					},
					{
						columns: [4],
						hStyles: { width: '10rem' },
					},
					{
						columns: [5, 6],
						hStyles: { width: '10rem'},
					},
					{
						columns: [7],
						hStyles: { width: '20rem' },
					},
					{
						columns: [8],
						hStyles: { width: '10rem' },
					},
					{
						columns: [9, 10, 11, 12, 13, 14, 15, 16],
						hStyles: { width: '10rem' },
					},
					{
						columns: [17],
						hStyles: { width: '13rem' },
					},
					{
						columns: [18, 19, 20],
						hStyles: { width: '10rem' },
					},
					{
						columns: [21],
						hStyles: { width: '13rem' },
					},
					{
						columns: [22],
						hStyles: { width: '12rem' },
					},
					{
						columns: [23],
						hStyles: { width: '10rem' },
					},
					{
						columns: [24],
						hStyles: { width: '18rem' },
					},
				];
			}	
			if (layout.toUpperCase() === "JAKAFI") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1],
						hStyles: { width: '16rem' },
					},
					{
						columns: [2],
						hStyles: { width: '9rem' },
					},
					{
						columns: [3, 4],
						hStyles: { width: '6rem' },
					},
					{
						columns: [5, 6],
						hStyles: { width: '10rem' },
					},
					{
						columns: [7],
						hStyles: { width: '8rem' },
					},
					{
						columns: [8],
						hStyles: { width: '20rem' },
					},
					{
						columns: [9, 10, 11],
						hStyles: { width: '10rem' },
						bStyles: { width:'10rem' },
					},
					{
						columns: [12],
						hStyles: { width: '14rem' },
					},
					{
						columns: [13, 14, 15, 16, 17, 18],
						hStyles: { width: '10rem' },
						bStyles: { width:'10rem' },
					},
					{
						columns: [19],
						hStyles: { width: '19rem' },
					},
				];
			}
			break;
			case "Alert Listing":
			if (layout.toUpperCase() === "PEMAZYRE") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1, 2, 6],
						hStyles: { width: '10rem' },
					},
					{
						columns: [3],
						hStyles: { width: '20rem' },
					},
					{
						columns: [4, 5],
						hStyles: { width: '8rem' },
					},
					{
						columns: [7, 8],
						hStyles: { width: '17rem' },
					},
				];
			}
			if (layout.toUpperCase() === "MONJUVI") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1, 2, 6],
						hStyles: { width: '10rem' },
					},
					{
						columns: [3],
						hStyles: { width: '20rem' },
					},
					{
						columns: [4, 5],
						hStyles: { width: '8rem' },
					},
					{
						columns: [7, 8],
						hStyles: { width: '17rem' },
					},
				];
			}	
			if (layout.toUpperCase() === "JAKAFI") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1, 2, 6],
						hStyles: { width: '10rem' },
					},
					{
						columns: [3],
						hStyles: { width: '20rem' },
					},
					{
						columns: [4, 5],
						hStyles: { width: '8rem' },
					},
					{
						columns: [7, 8],
						hStyles: { width: '17rem' },
					},
				];
			}
			break;
			case "MorphoSys Monjuvi HCP Targets":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: '27rem' },
				},
				{
					columns: [1],
					hStyles: { width: '8rem' },
				},
				{
					columns: [2],
					hStyles: { width: '10rem' },
				},
				{
					columns: [3],
					hStyles: { width: '6rem' },
				},
				{
					columns: [4, 7],
					hStyles: { width: '16rem' },
				},
				{
					columns: [5],
					hStyles: { width: '8rem' },
				},
				{
					columns: [6],
					hStyles: { width: '10rem' },
				},
			];
			break;
		case "HCP Barrier History":
			tableProps.colOverrides = [
				  {
					columns: [1],
					hStyles: { width: "10rem" },
				  },
				  {
					columns: [2],
					hStyles: { width: "9rem" },
				  },
				  {
					columns: [3, 4],
					hStyles: { width: "6rem" },
				  },
				  {
					columns: [5, 6, 7, 8],
					hStyles: { width: "9rem" },
				  },
				  {
					columns: [9],
					hStyles: { width: "10rem" },
				  },
				  {
					columns: [10],
					hStyles: { width: "13rem" },
				  },
				  {
					columns: [11],
					hStyles: { width: "10rem" },
				  },
				  {
					columns: [12],
					hStyles: { width: "15rem" },
				  },
				];
			break;
		case "Prospecting":
		case "Exploratory Targets":
		case "Growth Opportunity":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: '27rem' },
				}, {
					columns: [1],
					hStyles: { width: '10rem' },
				}, {
					columns: [2, 3],
					hStyles: { width: '8rem' },
				}, {
					columns: [4, 5, 6, 7, 8, 9, 10, 11],
					hStyles: { width: '12rem' },
				}, {
					columns: [12],
					hStyles: { width: '19rem' },
				},
			];
			break;
		case "Alert HCPs":
			if (layout.toUpperCase() === "MONJUVI") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1],
						hStyles: { width: '14rem' },
					},
					{
						columns: [2],
						hStyles: { width: '9rem' },
					},
					{
						columns: [3],
						hStyles: { width: '6rem' },
					},
					{
						columns: [4],
						hStyles: { width: '10rem' },
					}, {
						columns: [5, 6],
						hStyles: { width: '8rem' },
					}, {
						columns: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
						hStyles: { width: '10rem' },
					}, {
						columns: [17],
						hStyles: { width: '19rem', },
					},
				];
			} else {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					}, 
					{
						columns: [1],
						hStyles: { width: '14rem' },
					},
					{
						columns: [2],
						hStyles: { width: '9rem' },
					},
					{
						columns: [3, 4],
						hStyles: { width: '6rem' },
					},
					{
						columns: [5, 6, 7],
						hStyles: { width: '10rem' },
					}, {
						columns: [8, 9, 10, 11, 12, 13, 14, 15],
						hStyles: { width: '10rem' },
					}, {
						columns: [16],
						hStyles: { width: '19rem' },
					},
				];	
			}


			break;
		case "Primary Targets":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: '27rem' },
				}, {
					columns: [1, 5],
					hStyles: { width: '10rem' },
				}, {
					columns: [2, 3],
					hStyles: { width: '6rem' },
				}, {
					columns: [4],
					hStyles: { width: '8rem' },
				}, {
					columns: [6],
					hStyles: { width: '12rem' },
				}, {
					columns: [7, 8],
					hStyles: { width: '17rem' },
				},
			];
			break;
		case "New Patients":
			if (activeTeam === "HO1" || activeTeam === "HO2") {
				if (layout.toUpperCase() === "JAKAFI") {
					tableProps.colOverrides = [
						{
							columns: [0],
							hStyles: { width: '27rem' },
						},
						{
							columns: [1],
							hStyles: { width: '11rem' },
						},
						{
							columns: [2, 5],
							hStyles: { width: '8rem' },
						},
						{
							columns: [3, 4],
							hStyles: { width: '7rem' },
						},
						{
							columns: [6, 7, 8, 9],
							hStyles: { width: '9rem' },
						},
						{
							columns: [10],
							hStyles: { width: '17rem' },
						}
					];
				}
				if (layout.toUpperCase() === "PEMAZYRE") {
					tableProps.colOverrides = [
						{
							columns: [0],
							hStyles: { width: '27rem' },
						},
						{
							columns: [1, 2],
							hStyles: { width: '11rem' },
						},
						{
							columns: [3, 4],
							hStyles: { width: '7rem' },
						},
						{
							columns: [5, 6, 7, 8, 9],
							hStyles: { width: '9rem' },
						},
						{
							columns: [10],
							hStyles: { width: '17rem' },
						}
					];
				}
			}
			else if (activeTeam === "OCNE") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1, 2],
						hStyles: { width: '11rem' },
					},
					{
						columns: [3, 4],
						hStyles: { width: '7rem' },
					},
					{
						columns: [5, 6, 7, 8, 9],
						hStyles: { width: '10rem' },
					},
					{
						columns: [10],
						hStyles: { width: '17rem' },
					}
				];
			}
			break;
		case "New Prescribers":
			if (layout.toUpperCase() === "JAKAFI") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1],
						hStyles: { width: '12rem' },
					},
					{
						columns: [2, 3],
						hStyles: { width: '7rem' },
					},
					{
						columns: [4, 5, 6],
						hStyles: { width: '10rem' },
					},
					{
						columns: [7],
						hStyles: { width: '8rem' },
					},
					{
						columns: [8],
						hStyles: { width: '17rem' },
					},
				];
			}
			break;
		case "Pemazyre New Prescribers":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: '27rem' },
				},
				{
					columns: [1],
					hStyles: { width: '12rem' },
				},
				{
					columns: [2, 3, 4],
					hStyles: { width: '7rem' },
				},
				{
					columns: [5, 6],
					hStyles: { width: '8rem' },
				},
				{
					columns: [7, 8, 9],
					hStyles: { width: '10rem' },
				},

				{
					columns: [10, 11],
					hStyles: { width: '17rem' },
				}
			];
		break;
		case "Prescribers":
			if (layout.toUpperCase() === "JAKAFI") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1],
						hStyles: { width: '12rem' },
					},
					{
						columns: [2],
						hStyles: { width: '8rem' },
					},
					{
						columns: [3, 4],
						hStyles: { width: '6rem' },
					},
					{
						columns: [5, 6, 7],
						hStyles: { width: '8rem' },
					},
					{
						columns: [8, 9, 10, 11, 12, 13, 14, 15],
						hStyles: { width: '10rem' },
					},
					{
						columns: [16],
						hStyles: { width: '17rem' },
					}
				];
			}
			if (layout.toUpperCase() === "PEMAZYRE") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: '27rem' },
					},
					{
						columns: [1, 6, 10],
						hStyles: { width: '8rem' },
					},
					{
						columns: [2, 3, 7, 8],
						hStyles: { width: '10rem' },
					}, 
					{
						columns: [4, 5],
						hStyles: { width: '6rem' },
					},
					{
						columns: [11],
						hStyles: { width: '17rem' },
					}
				]
			}
			break;
		case "Decliners":
			tableProps.colOverrides = [
				{
					columns: [1],
					hStyles: { width: '5rem' },
				},
				{
					columns: [2],
					hStyles: { width: '5rem' },
				},
				{
					columns: [3],
					hStyles: { width: '6rem' },
				},
				{
					columns: [4],
					hStyles: { width: '6rem' },
				},
				{
					columns: [5, 6],
					hStyles: { width: '10rem' },
				},
				{
					columns: [7],
					hStyles: { width: '17rem' },
				},
			];
			break;
		case "Targets w/ No Rx":
			tableProps.colOverrides = [
				{
					columns: [1, 6, 7],
					hStyles: { width: '9rem' },
				},
				{
					columns: [2],
					hStyles: { width: '20rem' },
				},
				{
					columns: [3, 5],
					hStyles: { width: '7rem' },
				},
				{
					columns: [4],
					hStyles: { width: '6rem' },
				},
				{
					columns: [8],
					hStyles: { width: '17rem' },
				},
			];
			break;
		case "Existing Accounts Not Ordering (QTD)":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "30rem" },
				},
				{
					columns: [1],
					hStyles: { width: "10rem" },
				},
				{
					columns: [2],
					hStyles: { width: "11rem" },
				},
				{
					columns: [3],
					hStyles: { width: "6rem" },
				},
				{
					columns: [4],
					hStyles: { width: "7rem" },
				},
				{
					columns: [5],
					hStyles: { width: "10rem" },
				},
				{
					columns: [6],
					hStyles: { width: "10rem" },
				},
				{
					columns: [7],
					hStyles: { width: "9rem" },
				},
				{
					columns: [8],
					hStyles: { width: "7rem" },
				},
				{
					columns: [9],
					hStyles: { width: "20rem" },
				},
			];
			break;
		case "NPP Alert HCPs (R12M)":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "30rem" },
				},
				{
					columns: [1],
					hStyles: { width: "10rem" },
				},
				{
					columns: [2],
					hStyles: { width: "7rem" },
				},
				{
					columns: [3],
					hStyles: { width: "8rem" },
				},
				{
					columns: [4],
					hStyles: { width: "8rem" },
				},
				{
					columns: [5],
					hStyles: { width: "8rem" },
				},
				{
					columns: [6],
					hStyles: { width: "10rem" },
				},
				{
					columns: [7],
					hStyles: { width: "9rem" },
				},
				{
					columns: [8],
					hStyles: { width: "11rem" },
				},
				{
					columns: [9],
					hStyles: { width: "8rem" },
				},
				{
					columns: [10],
					hStyles: { width: "8rem" },
				},
				{
					columns: [11],
					hStyles: { width: "11rem" },
				},
				{
					columns: [12],
					hStyles: { width: "12rem" },
				},
				{
					columns: [13],
					hStyles: { width: "20rem" },
				},
			];
			break;
		case "HCP Targets":
		case "Monjuvi Prescribers":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "30rem" },
				},
				{
					columns: [1],
					hStyles: { width: "15rem" },
				},
				{
					columns: [2],
					hStyles: { width: "9rem" },
				},
				{
					columns: [3],
					hStyles: { width: "7rem" },
				},
				{
					columns: [4],
					hStyles: { width: "8rem" },
				},
				{
					columns: [5],
					hStyles: { width: "8rem" },
				},
				{
					columns: [6],
					hStyles: { width: "10rem" },
				},
				{
					columns: [7],
					hStyles: { width: "10rem" },
				},
				{
					columns: [8],
					hStyles: { width: "9rem" },
				},
				{
					columns: [9],
					hStyles: { width: "9rem" },
				},
				{
					columns: [10],
					hStyles: { width: "10rem" },
				},
				{
					columns: [11],
					hStyles: { width: "11rem" },
				},
				{
					columns: [12],
					hStyles: { width: "9rem" },
				},
				{
					columns: [13],
					hStyles: { width: "20rem" },
				},
			];
			break;
		case "Monjuvi Targets":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: '27rem' },
				},
				{
					columns: [1, 5, 6],
					hStyles: { width: '10rem' },
				},
				{
					columns: [2, 3],
					hStyles: { width: '6rem' },
				},
				{
					columns: [4],
					hStyles: { width: '8rem' },
				},
				{
					columns: [7, 8],
					hStyles: { width: '17rem' },
				},
			];
			break;
		case "HCP Targets Not Called (R13W)":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "30rem" },
				},
				{
					columns: [1],
					hStyles: { width: "6rem" },
				},
				{
					columns: [2],
					hStyles: { width: "7rem" },
				},
				{
					columns: [3],
					hStyles: { width: "8rem" },
				},
				{
					columns: [4],
					hStyles: { width: "8rem" },
				},
				{
					columns: [5],
					hStyles: { width: "8rem" },
				},
				{
					columns: [6],
					hStyles: { width: "11rem" },
				},
				{
					columns: [7],
					hStyles: { width: "9rem" },
				},
				{
					columns: [8],
					hStyles: { width: "11rem" },
				},
				{
					columns: [9],
					hStyles: { width: "8rem" },
				},
				{
					columns: [10],
					hStyles: { width: "8rem" },
				},
				{
					columns: [11],
					hStyles: { width: "11rem" },
				},
				{
					columns: [12],
					hStyles: { width: "11rem" },
				},
				{
					columns: [13],
					hStyles: { width: "20 rem" },
				},
			];
			break;
		case "HCP MOR Calls (R13W)":
		case "HCPs sent MOR RTE":
		case "HCP Targets Not Emailed (R13W)":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "30rem" },
				},
				{
					columns: [1],
					hStyles: { width: "8rem" },
				},
				{
					columns: [2],
					hStyles: { width: "7rem" },
				},
				{
					columns: [3],
					hStyles: { width: "8rem" },
				},
				{
					columns: [4],
					hStyles: { width: "8rem" },
				},
				{
					columns: [5],
					hStyles: { width: "8rem" },
				},
				{
					columns: [6],
					hStyles: { width: "13rem" },
				},
				{
					columns: [7],
					hStyles: { width: "9rem" },
				},
				{
					columns: [8],
					hStyles: { width: "11rem" },
				},
				{
					columns: [9],
					hStyles: { width: "8rem" },
				},
				{
					columns: [10],
					hStyles: { width: "12rem" },
				},
				{
					columns: [11],
					hStyles: { width: "10rem" },
				},
				{
					columns: [12],
					hStyles: { width: "12rem" },
				},
				{
					columns: [13],
					hStyles: { width: "20rem" },
				},
			];
			break;
		case "NPP Alerts":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "30rem" },
				},
				{
					columns: [1],
					hStyles: { width: "7rem" },
				},
				{
					columns: [2],
					hStyles: { width: "12rem" },
				},
				{
					columns: [3],
					hStyles: { width: "22rem" },
				},
				{
					columns: [4],
					hStyles: { width: "20rem" },
				},
				{
					columns: [5],
					hStyles: { width: "7rem" },
				},
				{
					columns: [6],
					hStyles: { width: "7rem" },
				},
				{
					columns: [8, 9, 11],
					hStyles: { width: "7rem" },
				},
				{
					columns: [12],
					hStyles: {width: "12rem"},
				},
			];
			break;
		case "Accounts with Orders":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "20rem" },
				},
				{
					columns: [1],
					hStyles: { width: "20rem" },
				},
				{
					columns: [2],
					hStyles: { width: "10rem" },
				},
				{
					columns: [3],
					hStyles: { width: "6rem" },
				},
				{
					columns: [4],
					hStyles: { width: "5rem" },
				},
				{
					columns: [5],
					hStyles: { width: "7rem" },
				},
				{
					columns: [6],
					hStyles: { width: "7rem" },
				},
				{
					columns: [7],
					hStyles: { width: "7rem" },
				},
				{
					columns: [8],
					hStyles: { width: "3rem" },
				},
				{
					columns: [9],
					hStyles: { width: "4rem" },
				},
				{
					columns: [10],
					hStyles: { width: "6rem" },
				},
				{
					columns: [11],
					hStyles: { width: "10rem" },
				},
			];
			break;
		case "Account Order History":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "20rem" },
				},
				{
					columns: [1],
					hStyles: { width: "20rem" },
				},
				{
					columns: [2],
					hStyles: { width: "10rem" },
				},
				{
					columns: [3],
					hStyles: { width: "6rem" },
				},
				{
					columns: [4],
					hStyles: { width: "5rem" },
				},
				{
					columns: [5],
					hStyles: { width: "7rem" },
				},
				{
					columns: [6],
					hStyles: { width: "8rem" },
				},
				{
					columns: [7],
					hStyles: { width: "5rem" },
				},
				{
					columns: [8],
					hStyles: { width: "20rem" },
				},
			];
			break;
		case "New Orders this Week - Rolling 7 Days":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "20rem" },
				},
				{
					columns: [1],
					hStyles: { width: "16rem" },
				},
				{
					columns: [2],
					hStyles: { width: "7rem" },
				},
				{
					columns: [3],
					hStyles: { width: "6rem" },
				},
				{
					columns: [4],
					hStyles: { width: "5rem" },
				},
				{
					columns: [5],
					hStyles: { width: "8rem" },
        },
        {
					columns: [6],
					hStyles: { width: "12rem" },
        },
        {
					columns: [7],
					hStyles: { width: "18rem" },
				},
			];
			break;
		case "Rep Interactions":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "12rem" },
				},
			];
			break;
		case "Pemazyre Targets":
			if (activeTeam === "HO1" || activeTeam === "HO2") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: "26rem" },
					},
					{
						columns: [1, 3, 4, 5, 7, 8, 9, 10],
						hStyles: { width: "9rem" },
					},
					{
						columns: [2, 6],
						hStyles: { width: "12rem" },
					},
					{
						columns: [11, 12],
						hStyles: { width: "17rem" },
					},
				];
			}
			else if (activeTeam === "OCNE") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: "26rem" },
					},
					{
						columns: [1],
						hStyles: { width: "11rem" },
					},
					{
						columns: [2, 3],
						hStyles: { width: "6rem" },
					},
					{
						columns: [4, 5, 7, 8, 9, 10],
						hStyles: { width: "9rem" },
					},
					{
						columns: [6, 11, 12, 13, 14],
						hStyles: { width: "12rem" },
					},
					{
						columns: [15, 16],
						hStyles: { width: "17rem" },
					},
				];
			}
			break;
		case "Pemazyre Interactions": 
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "26rem" },
				},
				{
					columns: [1],
					hStyles: { width: "10rem" },
				},
				{
					columns: [2],
					hStyles: { width: "12rem" },
				},
				{
					columns: [3],
					hStyles: { width: "15rem" },
				},
				{
					columns: [4, 5, 6, 8],
					hStyles: { width: "9rem" },
				},
				{
					columns: [9, 10],
					hStyles: { width: "17rem" },
				},
			];
			break;
		case "New APP Prescribers": 
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "27rem" },
				},
				{
					columns: [1],
					hStyles: { width: "11rem" },
				},
				{
					columns: [2, 3],
					hStyles: { width: "6rem" },
				},
				{
					columns: [4, 5, 6, 7, 10, 12, 16],
					hStyles: { width: "10rem" },
				},
				{
					columns: [11],
					hStyles: { width: "11rem" },
				},
				{
					columns: [8, 9, 13, 14, 15],
					hStyles: { width: "9rem" },
				},
				{
					columns: [17 ,18],
					hStyles: { width: "17rem" },
				},
			];
			break;
		case "APP Barriers": 
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "27rem" },
				},
				{
					columns: [1, 2, 5, 7, 9, 11],
					hStyles: { width: "12rem" },
				},
				{
					columns: [3, 4],
					hStyles: { width: "6rem" },
				},
				{
					columns: [6, 8, 10, 12],
					hStyles: { width: "10rem" },
				},
				{
					columns: [13, 14],
					hStyles: { width: "17rem" },
				},
			];
			break;
		case "APP Initiating HCP": 
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "27rem" },
				},
				{
					columns: [1, 9, 11, 12, 16],
					hStyles: { width: "12rem" },
				},
				{
					columns: [2, 3],
					hStyles: { width: "6rem" },
				},
				{
					columns: [4, 9],
					hStyles: { width: "8rem" },
				},
				{
					columns: [5, 6, 7, 8, 10, 13, 14, 15, 20, 21, 22],
					hStyles: { width: "10rem" },
				},
				{
					columns: [18],
					hStyles: { width: "15rem" },
				},
				{
					columns: [23, 24],
					hStyles: { width: "17rem" },
				},
			];
			break;
		case "HCP Targets Not Called in Last 8  Weeks":
			tableProps.colOverrides = [
				{
					columns: [1],
					hStyles: { width: "7rem" },
				},
				{
					columns: [2],
					hStyles: { width: "6rem" },
				},
				{
					columns: [3],
					hStyles: { width: "6rem" },
				},
				{
					columns: [4, 5],
					hStyles: { width: "9.5rem" },
				},
				{
					columns: [6],
					hStyles: { width: "17rem" },
				},
			];
			break;
		case "SP Sales CW vs. PW":
			tableProps.colOverrides = [
				{
					columns: [1],
					hStyles: { width: "11rem" },
				},
				{
					columns: [2],
					hStyles: { width: "8rem" },
				},
				{
					columns: [3],
					hStyles: { width: "7rem" },
				},
				{
					columns: [4],
					hStyles: { width: "7rem" },
				},
				{
					columns: [5, 6, 7, 8],
					hStyles: { width: "2rem" },
				},
			];
			break;
		case "Direct Sales CW vs. PW":
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "18rem" },
				},
				{
					columns: [1],
					hStyles: { width: "6rem" },
				},
				{
					columns: [2],
					hStyles: { width: "6rem" },
				},
				{
					columns: [3, 4, 5, 6],
					hStyles: { width: "2rem" },
				},
			];
			break;
		case "BV Enrollments":
			if (activeTeam === "HO1" || activeTeam === "HO2") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: "18rem" },
					},
					{
						columns: [1],
						hStyles: { width: "8rem" },
					},
					{
						columns: [2, 3],
						hStyles: { width: "10rem" },
					},
					{
						columns: [4, 5],
						hStyles: { width: "6rem" },
					},
					{
						columns: [6, 10, 13, 14],
						hStyles: { width: "10rem" },
					},
					{
						columns: [7, 8, 9],
						hStyles: { width: "12rem" },
					},
					{
						columns: [15],
						hStyles: { width: '17rem' }
					}
				];
			}
			else if (activeTeam === "OCNE") {
				tableProps.colOverrides = [
					{
						columns: [0],
						hStyles: { width: "18rem" },
					},
					{
						columns: [1, 12, 18],
						hStyles: { width: "8rem" },
					},
					{
						columns: [2, 3, 9],
						hStyles: { width: "10rem" },
					},
					{
						columns: [4, 5],
						hStyles: { width: "6rem" },
					},
					{
						columns: [6, 7, 8, 10, 11, 13, 14, 15, 17],
						hStyles: { width: '11rem' },
					},
					{
						columns: [19, 20],
						hStyles: { width: '17rem' }
					}
				];
			}
			break;
		case "Direct Sales Accts": //Jakafi
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "18rem" },
				},
				{
					columns: [1],
					hStyles: { width: "12rem" },
				},
				{
					columns: [2],
					hStyles: { width: "10rem" },
				},
				{
					columns: [3, 4],
					hStyles: { width: "6rem" },
				},
				{
					columns: [5, 6, 7, 8, 10, 11],
					hStyles: { width: '11rem' }
				},
				{
					columns: [12],
					hStyles: { width: '17rem' }
				}
			];
			break;
		case "Direct Sales Accounts": //Pemazyre
			tableProps.colOverrides = [
				{
					columns: [0],
					hStyles: { width: "22rem" },
				},
				{
					columns: [1],
					hStyles: { width: "12rem" },
				},
				{
					columns: [2],
					hStyles: { width: "10rem" },
				},
				{
					columns: [3, 4],
					hStyles: { width: "6rem" },
				},
				{
					columns: [5, 6, 7, 8],
					hStyles: { width: '11rem' },
				},
				{
					columns: [11],
					hStyles: { width: '17rem' }
				}
			];
			break;
		case "SP Sales History":
		case "Direct Sales History":
		case "Direct Sales Bottles":
		case "Top 10 Direct Sales Accounts":
		case "Refill Tracker":
		default:
			break;
	}

	let exportId;
	if (chartName === 'HCP Targets') {
		exportId = tables['Monjuvi HCP Targets - Export'];
	}

	const handleFilterChange = ({ key }) => {
		const currentUrl = encodeURIComponent(key?.replace(/%/g, 'Percent'));
		history.replace(currentUrl);
		window.analytics?.track('Button Clicked', { text: chartName, team: activeTeam, context: 'Field Sales - Details Tab - Table Clicked' });
	};

	// Maintains backwards compatibility with older-style URL
	if (Number.isNaN(Number(subTab)) === false) {
		const tableIndex = Number(subTab);
		const tableKeys = Object.keys(tables);

		if (tableIndex < tableKeys.length) {
			handleFilterChange({
				key: Object.keys(tables)[tableIndex]
			});
		} else {
			handleFilterChange({
				key: Object.keys(tables)[0]
			});
		}
	}

	const detailsTable = (props) => {
		let newMashupId = tables[chartName];
		return (
			<QSMashupObject
				{...props}
				tableProps={tableProps}
				appId={appId}
				contentStyle={{ overflowY: 'hidden', overflowX: 'auto' }}
				mashupId={newMashupId}
				exportId={(chartName === 'HCP Targets') ? tables['Monjuvi HCP Targets - Export'] : newMashupId}
				key={`main_${newMashupId}`}
				isTable
				isSubtitleRed={chartName === 'New Orders this Week' ? true : false}
				showExports
				dynamic
			/>
		)
	};

	return (
		<Layout hasSider style={{ height: '100%', overflow: 'auto', transform: 'scale(1.0)' }}>
			<Sider
				width={250} collapsible theme="light"
				style={{ height: 'calc(100% - 4rem)', overflow: 'auto' }}
			>
				<Menu
					theme="light"
					mode="inline"
					selectedKeys={[subTab]}
					onSelect={handleFilterChange}
				>
					{Object.keys(tables).map((chartName) => (
						<Menu.Item
							key={chartName}
							title={chartName}
							icon={<LinkOutlined />}
							style={{whiteSpace: 'normal', lineHeight: 'normal'}}
						>
							{chartName}
						</Menu.Item>
					))}
				</Menu>
			</Sider>
			<Layout style={{backgroundColor: 'white'}}>
				{detailsTable(props)}
			</Layout>
		</Layout>
	);
};

export default DetailsPane;
