import React, { useEffect, useState, useContext } from 'react';
import ReactResizeObserver from 'rc-resize-observer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
	VerticalTimeline,
	VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { QSAppMetadataFamily } from '@trinity-incyte/recoil';
import { generate as generatePalette, magenta } from '@ant-design/colors';
import { Empty, Image, Tag } from 'antd';

import imgSpeaker from '../../../assets/Images/SP01_w.png';
import Utils, { editQueryParameter, removeQueryParameter } from '@trinity-incyte/utils';
import useFilter from '../Hooks/useFilter';
import { generateGetMoreData } from '@trinity-incyte/utils';
import { useBackTop, KPIBox } from '@trinity-incyte/ui';
import './ComplexInteractionTimeline.scss';
import { ConfigContext } from '@trinity-incyte/context';
import { GlobalOutlined, MailOutlined, PhoneFilled } from '@ant-design/icons';
import { imgAlert, scheduledMail } from 'libs/assets/src/lib/images';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const mosaicGreen = generatePalette('#00cc22');

const InteractionsFilterer = (props) => {
	const {
		defaultFilterValues,
		listOfInteraction,
		listOfTeams,
		listOfCompanies,
		setFilter,
		events,
		filteredEvents,
		counterContent
	} = props;

	const history = useHistory();
	const location = useLocation();

	const set_selectedCompany = (val) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			company: !val ? [] : listOfCompanies.filter((company) => company == val),
		}))
	};

	const set_selectedTeam = (val) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			team: !val ? [] : listOfTeams.filter((team) => team == val),
		}))
	};
	const set_selectedType = (val) => {
		val ? editQueryParameter('type', val, history, location) : removeQueryParameter('type', history, location);
		setFilter((prevFilter) => ({
			...prevFilter,
			interaction: !val ? [] : listOfInteraction.filter((interaction) => interaction == val),
		}))
	};

	let fromTable = events.reduce((acc, {interaction, team, company}) => {
		acc.types.All += 1;
		acc.companies.All += 1;
		acc.teams.All += 1;
		acc.types[interaction] = acc.types[interaction] +1 || 1;
		acc.companies[company] = acc.companies[company] +1 || 1;
		acc.teams[team] = acc.teams[team] +1 || 1;
		return acc;
	}, {
		companies: {
			All: 0,
		},
		teams: {
			All: 0,
		},
		types: {
			All: 0,
		}
	});
	fromTable = filteredEvents?.reduce((acc, {interaction, team, company}) => {
		acc.totalCount.All += 1;
		acc.totalCount.companies[company] =
			(acc.totalCount.companies[company] || 0) + 1;
			acc.totalCount.teams[team] =
			(acc.totalCount.teams[team] || 0) + 1;
			acc.totalCount.types[interaction] =
			(acc.totalCount.types[interaction] || 0) + 1;
		return acc;
	}, {
		...fromTable,
		totalCount: {
			All: 0,
			teams: {},
			companies: {},
			types: {},
		},
	});
	return useFilter({
		fromTable,
		defaultFilterValues,
		set_selectedCompany,
		set_selectedTeam,
		set_selectedType,
		hideCount: counterContent
	});
};

function hideFilterIfSimpleSelection(listOfOptions) {
	return listOfOptions.length < 2 ? [] : listOfOptions;
}

const hasText = (value) => (value && value !== '' && value !== '-');

const ComplexInteractionsTimeline = ( props ) => {
	const [events, set_events] = useState([]);
	const [tableObject, set_tableObject] = useState<any>();
	const [filter, setFilter] = useState<{}>({
		interaction: props.interactionType ? [props.interactionType] : []
	});
	const defaultFilterValues = {
		interaction: props.interactionType
	}
	const [filteredEvents, setFilteredEvents] = useState([]);
	const [companies, set_companies] = useState(new Set());
	const { profId } = useParams() as any;
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360,
		{ appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;

	useEffect(() => { // OnMount & OnUnmount
		const app = Mosaic.Qlik.app[appId];
		if ( profId && app ) {
			let selectionValue;
			let selectionField;
			if ( Number.isNaN( parseInt( profId, 10 ))) {
				// Custom selection needed
				const decodedSelection = atob( profId );
				[selectionField, selectionValue] = decodedSelection.split( ':' );
			} else {
				// It's a regular PROF ID
				selectionField = fields.get('Mosaic_HCP 360_PROF ID')?.id;
				selectionValue = parseInt( profId, 10 );
			}
			app.field( selectionField )
				.selectValues([selectionValue], false, true ).then(() => {
					// This is specific to the specific alert table
					const onQVizData = ( qViz ) => {
						const tempEvents = [];
						for (let ii = 0; ii < qViz.table.rows.length; ii += 1) {
							const row = qViz.table.rows[ii];

							var [
								{ qText: date },
								{ qText: type },
								{ qText: line1 },
								{ qText: line2 },
								{ qText: line3 },
								{ qText: line4 },
								{ qText: territory },
								{ qText: emp },
								{ qText: company },
								{ qText: interaction },
								{ qText: team },
							] = row.cells;

							set_companies((prev) => {
								prev.add(company);
								return prev;
							});

							const commonIconStyles = {
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							};

							const commonContentStyles = {
								display: 'flex',
								flexDirection: 'column',
							};

							// TODO - Confirm overriding the default values like company and team
							const event: any = {
								key: ii,
								date,
								type,
								line1,
								line2,
								line3,
								line4,
								territory,
								emp,
								team: team === '-' ? 'Other' : team,
								interaction,
								company:
									company === company.toUpperCase()
										? company[0] + company.substr(1).toLowerCase()
											: company,
								contentStyle: {
									background: 'var(--WhiteFogHEX)',
									color: '#0D0D0D',
									...commonContentStyles,
								}
							};

							switch (event.type?.toUpperCase()) {
								case 'CALL':
								case 'DIRECT CALL':
								case 'PLANNED CALL':
								case 'INFLUENCED CALL':
								case 'TLL ENGAGEMENT':
								case 'MSL ENGAGEMENT':
								case 'TLL ENGAGEMENT':
									event.icon = <PhoneFilled style={{ marginTop: '1rem' }}/>;
									event.iconStyle = {
										background: magenta[7],
										color: '#fff',
										...commonIconStyles,
									};
									break;
								case 'SPEAKER PROGRAM ATTENDEE':
								case 'SPEAKER PROGRAM LEADER':
									event.icon = {
										content: <Image
											preview={false}
											height={'2rem'}
											width={'2rem'}
											src={imgSpeaker}
										/>
									}
									event.iconStyle = {
										background: '#21ba45',
										color: '#fff',
										...commonIconStyles,
									};
									break;
								case 'WEB INTERACTION':
									event.icon = <GlobalOutlined style={{ marginTop: '1rem' }}/>;
									event.iconStyle = {
										background: mosaicGreen[4],
										color: '#fff',
										...commonIconStyles,
									};
									break;
								case 'HQ EMAIL':
								case 'REP TRIGGERED EMAIL (SENT)':
									event.icon = <MailOutlined style={{ marginTop: '1rem' }}/>;
									event.iconStyle = {
										background: 'var(--DarkGreyHEX)',
										color: '#fff',
										...commonIconStyles,
									};
									break;
								case 'REP TRIGGERED EMAIL (SCHEDULED)':
									event.icon = {
										content: <Image
											preview={false}
											height={'2.5rem'}
											width={'2.5rem'}
											src={scheduledMail}
										/>
									}
									event.iconStyle = {
										background: 'var(--DarkGreyHEX)',
										...commonIconStyles,
									};
									break;
								case 'PREDICTED MCE':
								case 'MF/PV NEW PATIENT DOSING':
								case 'INFORM':
								case 'BARRIER':
								case 'CLINICAL':
								case 'CCA DIAGNOSTIC':
								case 'NPP':
									event.icon = {
										content: <Image
											preview={false}
											height={'2.5rem'}
											width={'2.5rem'}
											src={imgAlert}
										/>
									}
									event.iconStyle = {
										background: 'var(--DarkGreyHEX)',
										...commonIconStyles,
									};
									break;
								default:
									event.icon = <MailOutlined style={{ marginTop: '1rem' }}/>;
									event.iconStyle = {
										background: 'var(--DarkGreyHEX)',
										color: '#fff',
										...commonIconStyles,
									};
									break;
							}
							tempEvents.push(event);
						}

						if (tempEvents.length) {
							set_events(tempEvents);
						}
					};

					let interactionsTable = ids.get('Mosaic_HCP 360 - Incyte Activity_Interactions')?.id;
					app.visualization
						.get(interactionsTable)
						.then(( qViz ) => {
							qViz.table.addOnDataListener(() => {
								onQVizData( qViz );
							});
							qViz.table._instance.getMoreData = generateGetMoreData();
							qViz.table._instance.getMoreData();
							set_tableObject( qViz );
						});
				});

				return () => {
					if ( app ) {
						app.field( fields.get('Mosaic_HCP 360_PROF ID')?.id ).clear();
					}
				};
		}
		}, []);

		useEffect(() => {
			return () => {
				if (tableObject && tableObject.close) tableObject.close();
			};
		}, [tableObject]);

		useEffect(() => {
			setFilteredEvents(
				events.filter((val) => {
					for (var prop in filter) {
						if (filter[prop] !== null && filter[prop].length > 0 && filter[prop].indexOf(val[prop]) == -1) {
							return false;
						}
					}
					return true;
				})
			);
		}, [events, filter]);

		const [set_scroller, onResizer, backTop] = useBackTop();

		const content = <>
			{!!filteredEvents.length ?
				<VerticalTimeline>
					{filteredEvents.map((val) =>  (
					<VerticalTimelineElement
						key={val.key}
						className="vertical-timeline-element--work"
						contentStyle={val.contentStyle}
						contentArrowStyle={{ borderRight: '1rem solid  var(--WhiteFogHEX)' }}
						date={
							<Tag className='date-label'>
							<>
								{Utils.moment(val.date).format( 'MM-DD-YYYY' )}
							</>
							</Tag>
						}
						iconStyle={val.iconStyle}
						icon={val.icon?.content || val.icon }
					>
						<Tag
							color= {(val.company === 'MorphoSys') ? '#db2828' : '#005cab'}
							style={{
								fontSize: '.8rem',
								boxShadow: '0px 0 2px 0px black',
								position: 'absolute',
								left: -7,
								top: -8,
							}}
						>
							<span>{val.company} {val.team}</span>
						</Tag>
						<h3 className="vertical-timeline-element-title">{val.type}</h3>

						{(hasText(val.line1)) && (<div>{val.line1}</div>)}
						{(hasText(val.line2)) && (<div>{val.line2}</div>)}
						{(hasText(val.line3)) && (<div>{val.line3}</div>)}
						{(hasText(val.line4)) && (<div>{val.line4}</div>)}

						{val.emp && val.emp !== '-' && (
							<>
								<br />
								<Tag style={{ width: 'fit-content', position: 'absolute', right: -8, bottom: 0, padding: '.75rem 1rem' }}>
									<h4 className="vertical-timeline-element-subtitle" style={{ fontSize: "1.07142857rem" }}>
										{val.emp}
									</h4>
								</Tag>
							</>
						)}
					</VerticalTimelineElement>
					))}
				</VerticalTimeline>
			: (
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description="No Events"
					className='no-events'
				/>
			)}
		</>;

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
			<InteractionsFilterer
				defaultFilterValues={defaultFilterValues}
				filteredEvents={filteredEvents}
				listOfInteraction={hideFilterIfSimpleSelection([ ...new Set(events.map((event) => event.interaction)), ])}
				listOfTeams={hideFilterIfSimpleSelection([ ...new Set(events.map((event) => event.team)), ])}
				listOfCompanies={hideFilterIfSimpleSelection([ ...new Set(events.map((event) => event.company)), ])}
				setFilter={setFilter}
				counterContent={<KPIBox
					header={<div style={{ padding: '5px 10px 5px 24px', fontSize:'1em', color:'#a333c8' }}>View As Table</div>}
					color='#a333c8'
					sashColor='#a333c8'
					size="sm"
                    style={{
                        outline: "1px solid #a333c8",
                        outlineOffset: '4px',
                        marginLeft: 'auto',
                        width: 'fit-content'
                    }}
					canClick={true}
					mashupId={ids.get('Mosaic_HCP 360 - Incyte Activity_Interactions view as a table')?.id}
					tableProps={{
						colOverrides: [
							{
								columns: [0, 6],
								hStyles: { width: '6rem' },
							},
							{
								columns: [1, 4, 5],
								hStyles: { width: '8rem' },
							},
							{
								columns: [2, 7],
								hStyles: { width: '7rem' },
							},
							{
								columns: [3],
								hStyles: { width: '20rem' },
							},
						],
					}}
				/>}
				events={events} />
			<ReactResizeObserver onResize={onResizer}>
			<div
				style={{ height: '100%', overflow: 'hidden auto' }}
				ref={set_scroller}
			>
				{ content }
				{backTop}
			</div>
			</ReactResizeObserver>
		</div>
	);
};

export default ComplexInteractionsTimeline;
