import { Row, Col } from 'antd';
import React, { createRef, useEffect, useState, useContext } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { GridOptions as GridOptionsAtom } from '@trinity-incyte/recoil';
import { lastUpdateTimeState } from '@trinity-incyte/recoil';
import Utils from '@trinity-incyte/utils';
import FilterSidebar from '../sidebars/filter-sidebar/filter-sidebar';
import ReactResizeObserver from 'rc-resize-observer';
import { ConfigContext, LibraryContext } from '@trinity-incyte/context';
import { useBackTop } from '../Other/back-top-indicator/back-top-indicator';
import { MenuOutlined } from '@ant-design/icons';

const MainRow = styled(Row)`
    height: 100%;
    overflow: hidden auto;
    padding: 4px;
`;

const ColContent = styled.div`
    height: 100%;
    background-color: #e5eff9;
    border: 0.5rem solid transparent;
    border-radius: 0.2rem;
`;

declare const window: any;

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const className = 'layout';

/* eslint-disable-next-line */
export interface RGLGridProps {}

export function RGLGrid(props: RGLGridProps | any) {
    const Config = useContext(ConfigContext);
    const Library = useContext(LibraryContext);
    const setLastUpdateTime = useSetRecoilState(lastUpdateTimeState);
    const [GridOptions, set_GridOptions] = useRecoilState(GridOptionsAtom);
    const { rHeight, cols, vUnits } = GridOptions || Config.App.grid;
    const [id, set_id] = useState(() => Utils.idGen('grid'));
    const [container, set_container] = useState<any>({});
    const [compactType, set_compactType] = useState('vertical');
    const [currentBreakpoint, set_currentBreakpoint] = useState('lg');
    const ref = createRef();
    const [set_scroller, onResizer, backTop] = useBackTop();
    // const currentLayout = getFromLS('layouts') || props.layout;
    const [currentLayout, set_currentLayout] = useState<any>();
    const [layoutArray, set_layoutArray] = useState<any>();
    const [content, set_content] = useState<any>((prev) => (
        <div
            id={id}
            style={{
                height: '100%',
                margin: '0',
                padding: '0',
                overflowX: 'hidden',
            }}
        />
    ));

    const layoutName = window.location.pathname;
    const getFromLS = (key) => {
        let ls = {};
        if (global.localStorage) {
            try {
                ls = JSON.parse(global.localStorage.getItem(layoutName)) || {};
            } catch (e) {}
        }
        return ls[key];
    };

    const saveToLS = (key, value) => {
        if (global.localStorage) {
            global.localStorage.setItem(
                layoutName,
                JSON.stringify({ [key]: value })
            );
        }
    };

    const rglMapping = (val, ind) => {
        // if ( !val || val.i === 'null' ) return;
        let item =
            (val.i && val.content) || Library?.getItem(val.libraryId || val.i);
        if (!item) return <></>;
        if (item.component) {
            const Compo = item.component;
            item = <Compo {...val.props} />;
        }

        if (item.content) {
            return (
                <div
                    key={val.i}
                    data-grid={val}
                    style={item.parentStyle}
                    className={item.parentClass}
                >
                    {item.content}
                    {!val.static && (
                        <MenuOutlined />
                    )}
                </div>
            );
        } else {
            return (
                <div key={val.i} data-grid={val} className={val.class} style={val.style}>
                    {item}
                    {!val.static && (
                        <MenuOutlined />
                    )}
                </div>
            );
        }
    };

    const getItemContent = (val) => {
        // if ( !val || val.i === 'null' ) return;
        let item =
            (val.i && val.content) || Library?.getItem(val.libraryId || val.i);
        if (!item) return <></>;
        if (item.component) {
            const Compo = item.component;
            item = <Compo {...val.props} />;
        }

        return (
            <ColContent style={val?.contentStyle} key={val.i}>
                {item?.content || item}
            </ColContent>
        );
    };

    const recursiveResponsiveRenderer = (item, index) => {
        let content;
        if (!item.items) {
            content = getItemContent(item);
        } else {
            const childContent = item.items.map(recursiveResponsiveRenderer);
            if (item.wrapped) {
                content = <Row style={item.wrapped} key={`row-${index}`}>{childContent}</Row>;
            } else {
                content = childContent;
            }
        }
        if (item.w) {
            return (
                <Col push={item.push || 0} span={item.w} style={item.style} key={`col-${index}`}>
                    {content}
                </Col>
            );
        }
        return (
            <Row gutter={props.gutter ?? [8, 8]} wrap={false} style={item.style} key={`row-${index}`}>
                {content}
            </Row>
        );
    };

    // const layouts = getFromLS('layouts') || {};
    const layouts = {};

    const onBreakpointChange = (breakpoint) => {
        set_currentBreakpoint(breakpoint);
    };

    const onLayoutChange = function (layout, layouts) {
        const layoutKeys = Object.keys(layouts);
        for (let ik = 0; ik < layoutKeys.length; ik += 1) {
            const breakpoint = layoutKeys[ik];
            for (let ii = 0; ii < layouts[breakpoint].length; ii += 1) {
                if (layouts[breakpoint][ii].i === 'null')
                    layouts[breakpoint].splice(ii, 1);
            }
        }

        saveToLS('layouts', layouts);
        setLastUpdateTime(Date.now());
    };

    const onDragStart = (elemParams) => {
        // console.log( 'onDragStart', elemParams );
    };

    const onDrag = (elemParams) => {
        // console.log( 'onDrag', elemParams );
    };

    const onDragStop = (elemParams) => {
        // console.log( 'onDragStop', elemParams );
    };

    const onResizeStart = (elemParams) => {
        // console.log( 'onResizeStart', elemParams );
    };

    const onResize = (elemParams) => {
        // console.log( 'onResize', elemParams );
    };

    const onResizeStop = (elemParams) => {
        // console.log( 'onResizeStop', elemParams );
    };

    const onDrop = (elemParams) => {
        // console.log( 'onDrop', elemParams );
    };

    useEffect(() => {
        if (window._vUnits) {
            // @ts-ignore
            set_GridOptions({
                ...Config.App.grid,
                vUnits: window._vUnits, // @ts-ignore
            });
        } else {
            set_GridOptions({
                ...Config.App.grid,
                vUnits: Config.App.grid.vUnits,
            });
        }
    }, [window._vUnits]); // @ts-ignore

    useEffect(() => {
        if (!props?.layout) return;
        set_currentLayout(props.layout);
    }, [props.layout]);

    useEffect(() => {
        if (!currentLayout) return;
        let layoutArray;
        if (currentLayout.lg) {
            layoutArray = currentLayout.lg;
        } else if (currentLayout.md) {
            layoutArray = currentLayout.md;
        } else if (currentLayout.sm) {
            layoutArray = currentLayout.sm;
        } else if (currentLayout.xs) {
            layoutArray = currentLayout.xs;
        } else if (currentLayout.responsive) {
            layoutArray = currentLayout.responsive;
        }
        set_layoutArray(layoutArray);
    }, [currentLayout]);

    useEffect(() => {
        const segment = document.getElementById(id);
        // if (!segment) return;
        const {
            clientHeight,
            clientLeft,
            clientTop,
            clientWidth,
            offsetHeight,
            offsetLeft,
            offsetTop,
            offsetWidth,
        } = segment;
        set_container({
            clientHeight,
            clientLeft,
            clientTop,
            clientWidth,
            offsetHeight,
            offsetLeft,
            offsetTop,
            offsetWidth,
        });
    }, []);

    useEffect(() => {
        if (!currentLayout) return;
        if (container.clientHeight) {
            const children = layoutArray.map(
                currentLayout.responsive
                    ? recursiveResponsiveRenderer
                    : rglMapping
            );

            const containerPadding = [4, 4];
            const margin = [0, 0];
            const gridHeight = container.offsetHeight;
            const rowHeight = Math.min(
                Math.floor((gridHeight - containerPadding.reduce((a, b) => a + b)) / vUnits),
                rHeight * 2
            );
            const rglProps = {
                className: props.gridClassName || className,
                rowHeight: props.rowHeight || rowHeight,
                onLayoutChange: props.onLayoutChange || onLayoutChange,
                cols: props.cols || cols,
                containerPadding: props.containerPadding || containerPadding,
                margin: props.margin || margin,
            };
            set_content((prev) => (
                <ReactResizeObserver onResize={onResizer}>
                    <div
                        id={id}
                        className={props.className || ''}
                        style={{
                            height: gridHeight,
                            margin: '0',
                            padding: '0',
                            overflowX: 'hidden',
                        }}
                    >
                        {!props.noSidebar && (
                            <FilterSidebar
                                hideSelections={false}
                                useTeamBasedFilter={props.useTeamBasedFilter}
                            />
                        )}
                        {!currentLayout.responsive ? (
                            <ResponsiveReactGridLayout
                                {...rglProps}
                                // ref={ref}
                                innerRef={set_scroller}
                                layouts={layouts}
                                onBreakpointChange={onBreakpointChange}
                                onLayoutChange={onLayoutChange}
                                // Calls when drag starts.
                                onDragStart={onDragStart}
                                // Calls on each drag movement.
                                onDrag={onDrag}
                                // Calls when drag is complete.
                                onDragStop={onDragStop}
                                // Calls when resize starts.
                                onResizeStart={onResizeStart}
                                // Calls when resize movement happens.
                                onResize={onResize}
                                // Calls when resize is complete.
                                onResizeStop={onResizeStop}
                                // Calls when some element has been dropped
                                onDrop={onDrop}
                                // WidthProvider option
                                measureBeforeMount
                                // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                                // and set `measureBeforeMount={true}`.
                                // useCSSTransforms={isMounted}
                                compactType={compactType}
                                preventCollision={!compactType}
                                isDroppable
                            >
                                {children}
                            </ResponsiveReactGridLayout>
                        ) : (
                            <MainRow
                                ref={set_scroller}
                                gutter={props.gutter ?? [8, 8]}
                            >
                                {children}
                            </MainRow>
                        )}
                        {props.children}
                        {backTop}
                    </div>
                </ReactResizeObserver>
            ));
        }
    }, [layoutArray]);

    return content;
}

export default RGLGrid;
