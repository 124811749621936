/* eslint-disable max-len */

import { LinkOutlined } from '@ant-design/icons';
import { 
  Button, 
  Col, 
  Row, 
  Tabs, 
  Tag, 
  List as ListAntd, 
  Typography, 
  Divider as AntDivider 
} from 'antd';
import React, { useMemo, useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import ImgDigitalPull from '../../../assets/Images/digital_pull.png';
import ImgDigitalPush from '../../../assets/Images/digital_push.png';
import ImgFaceToFace from '../../../assets/Images/face_to_face.png';
import ImgPeerInteraction from '../../../assets/Images/peer_to_peer.png';
import Config from '../../Config';
import { toggleSubPageSelector, activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';
import { QSMashupObject, BioOverview, ProfKPIGroup, NavLinkWrapper, MosaicSearch, KPIBox, SpeakerPrograms, Alerts, Barriers, Affiliations, Loader } from '@trinity-incyte/ui';
import { CSSProperties } from 'styled-components';
import { HCP360State } from '@trinity-incyte/context';
import { teamHasBrand } from '@trinity-incyte/utils';
import { ConfigContext } from '@trinity-incyte/context';
import { QSAppMetadataFamily } from "@trinity-incyte/recoil";
import { useMashup } from '@trinity-incyte/hooks';

const config = Config.Qlik.Prof360;
const { appId, items, fields } = config.ids;

const NavlinkButton = ({brand, address, text}) => {
  const activeTeam = useRecoilValue(activeTeamAtom);
  if (teamHasBrand(Config.App.teamToBrand, activeTeam, brand)) {
    return (
      <NavLinkWrapper to={address}>
        <Button
          icon={<LinkOutlined />}
          type="primary"
          size="small"
          style={{ marginRight: '10px', width: '100%' }}
        >
          {text}
        </Button>
      </NavLinkWrapper>
    )
  }  
  return (
    <div className='fauxAntButton'>
      {text}
    </div>
  )
}

const SubpageButton = ({brand, mashupId, tableProps, text}) => {
  const toggleSubPage = useSetRecoilState(toggleSubPageSelector);
  const activeTeam = useRecoilValue(activeTeamAtom);
  if (teamHasBrand(Config.App.teamToBrand, activeTeam, brand)) {
    return (
      <div style={{width: '100%', height: '100%', marginRight: '5px', marginLeft: '5px'}}>
        <Button
          icon={<LinkOutlined />}
          type="primary"
          size="small"
          style={{ marginRight: '10px', width: '100%' }}
          onClick={() => {
            toggleSubPage({
              mashupId: mashupId,
              tableProps: tableProps,
            });
          }}
        >
          {text}
        </Button>
      </div>
    );
  }
  return (
    <div style={{width: '100%', height: '100%', marginRight: '5px', marginLeft: '5px'}}>
      <div className='fauxAntButton'>
        {text}
      </div>
    </div>
  )
};

const HCP360 = {
  'Diagnosis Claims Quintile MF KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Diagnosis Claims Quintile MF KPI']} elementId="mosaic_chart_1" />
    ),
  },
  'Diagnosis Claims Quintile PV KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Diagnosis Claims Quintile PV KPI']} elementId="mosaic_chart_2" />
    ),
  },
  'Diagnosis Claims Quintile GVHD KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Diagnosis Claims Quintile GVHD KPI']} elementId="mosaic_chart_3" />
    ),
  },
  'Bottles R12Mo KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Bottles R12Mo KPI']} elementId="mosaic_chart_4" />
    ),
  },
  'Bottles C13W KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Bottles C13W KPI']} elementId="mosaic_chart_5" />
    ),
  },
  'Bottles YTD KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Bottles YTD KPI']} elementId="mosaic_chart_6" />
    ),
  },
  'SP Patient Active KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['SP Patient Active KPI']} elementId="mosaic_chart_7" />
    ),
  },
  'SP Patient R12Mo New KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['SP Patient R12Mo New KPI']} elementId="mosaic_chart_8" />
    ),
  },
  'SP Patient YTD New KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['SP Patient YTD New KPI']} elementId="mosaic_chart_9" />
    ),
  },
  'SP MF/PV Split Chart': {
    content: (
      <QSMashupObject appId={appId} mashupId={items['SP MF/PV Split Chart']} elementId="mosaic_chart_10" />
    ),
  },
  'Trial Card LTD KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Trial Card LTD KPI']} elementId="mosaic_chart_11" />
    ),
  },
  'Trial Card R12Mo KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Trial Card R12Mo KPI']} elementId="mosaic_chart_12" />
    ),
  },
  'IncyteCARES LTD KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['IncyteCARES LTD KPI']} elementId="mosaic_chart_13" />
    ),
  },
  'IncyteCARES R12Mo KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['IncyteCARES R12Mo KPI']} elementId="mosaic_chart_14" />
    ),
  },
  'Direct Sales At Location KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Direct Sales At Location KPI']} elementId="mosaic_chart_15" />
    ),
  },
  'Direct Sales GPO Bottles KPI': {
    content: (
      <QSMashupObject isText appId={appId} mashupId={items['Direct Sales GPO Bottles KPI']} elementId="mosaic_chart_16" />
    ),
  },
  'Quintile KPI Box': {
    content: (
      <KPIBox minimalHeader customClass="kpibox kpi-blue" header="Quintile">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Diagnosis Claims Quintile MF KPI']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Diagnosis Claims Quintile PV KPI']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Diagnosis Claims Quintile GVHD KPI']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Bottles KPI Box': {
    content: (
      <KPIBox minimalHeader customClass="kpibox kpi-purple" header="Bottles">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Bottles R12Mo KPI']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Bottles C13W KPI']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Bottles YTD KPI']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Vials KPI Box': {
    content: (
      <KPIBox minimalHeader customClass="kpibox kpi-purple" header="HCO Vials">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Bottles R12Mo KPI']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Bottles C13W KPI']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Bottles YTD KPI']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'SP Patients KPI Box': {
    content: (
      <KPIBox id="kpiBoxSPPatients" canClick mashupId={config.ids.items['Mosaic Overview']['Patients Popup Table']} minimalHeader customClass="kpibox kpi-blue" header="SP Patients">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['SP Patients Active KPI']} elementId="mosaic_chart_7" />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['SP Patients R12Mo New KPI']} elementId="mosaic_chart_8" />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['SP Patients YTD New KPI']} elementId="mosaic_chart_9" />
        </ListAntd>
      </KPIBox>
    ),
  },
  'SP MF/PV KPI Box': {
    content: (
      <KPIBox overlayHeader customClass="kpibox kpi-purple" header="SP MF/PV Split">
        <QSMashupObject appId={appId} mashupId={items['SP MF/PV Split Chart']} elementId="mosaic_chart_10" />
      </KPIBox>
    ),
  },
  'Trial Card KPI Box': {
    content: (
      <KPIBox customClass="kpibox kpi-green" header="TRIAL CARD">
        <ListAntd className="no-padding">
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['Trial Card LTD']} />
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['Trial Card R12Mo']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Trial Card / IncyteCares KPI Box': {
    content: (
      <KPIBox customClass="kpibox kpi-green">
        <Row align="middle" gutter={[0, 0]}>
          <Col span={3}></Col>
          <Col span={11} className="row-content row-content--title">Copay Card</Col>
          <Col span={10} className="row-content row-content--title">IncyteCARES</Col>
        </Row>
        <Row align="middle" gutter={[0, 0]}>
          <Col span={4} className="rowTitle rowTitle--title">R12M</Col>
            <QSMashupObject
              customFormatter={({ val1 }) => (<Col span={10} className="row-content">{val1}</Col>)}
              appId={appId}
              mashupId={items['Mosaic Overview']['JAKAFI']['Trial Card R12Mo']} />
            <QSMashupObject
              customFormatter={({ val1 }) => (<Col span={10} className="row-content">{val1}</Col>)}
              appId={appId}
              mashupId={items['Mosaic Overview']['JAKAFI']['IncyteCARES R12Mo KPI']} />
          </Row>
          <Row align="middle" gutter={[0, 0]}>
            <Col span={4} className="rowTitle rowTitle--title">LTD</Col>
            <QSMashupObject
              customFormatter={({ val1 }) => (<Col span={10} className="row-content">{val1}</Col>)}
              appId={appId}
              mashupId={items['Mosaic Overview']['JAKAFI']['Trial Card LTD']} />
            <QSMashupObject
              customFormatter={({ val1 }) => (<Col span={10} className="row-content">{val1}</Col>)}
              appId={appId}
              mashupId={items['Mosaic Overview']['JAKAFI']['IncyteCARES LTD KPI']} />
          </Row>
          <Row align="middle" gutter={[0, 0]}>
          <Col span={24} style={{textAlign:"right", marginTop:"-4px"}}>Unique Patients</Col>
          </Row>
      </KPIBox>
    ),
  },
  'IncyteCARES KPI Box': {
    content: (
      <KPIBox customClass="kpibox kpi-blue" header="IncyteCARES">
        <ListAntd className="no-padding">
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['IncyteCARES LTD KPI']} />
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['IncyteCARES R12Mo KPI']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Direct Sales KPI Box': {
    content: (
      <KPIBox customClass="kpibox kpi-blue" header="Direct Sales">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['Direct Sales At Location KPI']} />
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['Direct Sales GPO Bottles KPI']} />
        </ListAntd>
        <Typography.Title 
          className="kpibox--label" 
          style={{
            position: "absolute",
            right:"1.2rem", 
            bottom:"0.5rem",
            color: "rgba(0, 0, 0, 0.6)"
          }}
        >R12Mo</Typography.Title>
      </KPIBox>
    ),
  },
  'Mosaic Overview Prof Box': {
    component: (props) => {
      return (
        <div className={`full-height scroll-vertical`}>
          <ProfKPIGroup {...props} vertical />
        </div>
      );
    },
    content: (
      <ProfKPIGroup />
    ),
  },
  'Prof Bio Box': {
    component: (props) => (
      <>
        <BioOverview grid {...props} />
      </>
    )
  },
  'Prof Bio Table Box': {
    component: props => {
      // TODO fix issue with DraggableMenu / DraggablePanes
      // const screen = Utils.screen();
      // const [paneIndex, setPaneIndex] = useState( 0 );
      const [counters, setCounters] = useState<any>({});
      const panes = [
        {
          menuItem: `Education(${counters?.education || 0})`,
          pane: {
            key: 'tab0',
            content: (
              <Row>
                <Col span={24}>
                  <QSMashupObject
                    isTable
                    hideSearch
                    tableProps={{
                      colOverrides: [
                        {
                          columns: [0],
                          hStyles: { width: "8rem" },
                        },
                        {
                          columns: [1],
                          hStyles: { width: "15rem" },
                        },
                        {
                          columns: [2],
                          hStyles: { width: "25rem" },
                        },
                        {
                          columns: [3, 4],
                          hStyles: { width: "5rem" },
                        },
                      ]
                    }}
                    setCounter={({ rowCount: counter }) =>
                      setCounters((prevCount) => ({
                        ...prevCount,
                        education: counter
                      }))
                    }
                    appId={appId}
                    mashupId={items['Education Table - Bio']}
                  />
                </Col>
              </Row>
            ),
          },
        },
        {
          menuItem: `Current Roles(${counters?.currentRoles || 0})`,
          pane: {
            key: 'tab1',
            content: (
              <Row>
                <Col span={24}>
                  <QSMashupObject
                    isTable
                    hideSearch
                    tableProps={{
                      colOverrides: [
                        {
                          columns: [0, 3],
                          hStyles: { width: "6rem" },
                        },
                        {
                          columns: [1, 2],
                          hStyles: { width: "25rem" },
                        },
                      ]
                    }}
                    setCounter={({ rowCount: counter }) =>
                      setCounters((prevCount) => ({
                        ...prevCount,
                        currentRoles: counter
                      }))
                    }
                    appId={appId}
                    mashupId={items['Current Roles Table - Bio']}
                  />
                </Col>
              </Row>
            ),
          },
        },
        {
          menuItem: `Research & Interests(${counters?.interests?.reduce((acc, item) => acc + item, 0) || 0})`,
          pane: {
            key: 'tab2',
            content: (
              <Row>
                <Col span={8}>
                  <QSMashupObject
                    isTable
                    hideSearch
                    setCounter={({ rowCount: counter }) =>
                      setCounters((prevCount) => {
                        prevCount.interests = prevCount.interests || [];
                        prevCount.interests[0] = counter;
                        return prevCount
                      })
                    }
                    appId={appId}
                    mashupId={items['Current Research - Research and Interest']}
                  />
                </Col>
                <Col span={8}>
                  <QSMashupObject
                    isTable
                    hideSearch
                    setCounter={({ rowCount: counter }) =>
                      setCounters((prevCount) => {
                        prevCount.interests = prevCount.interests || [];
                        prevCount.interests[1] = counter;
                        return prevCount
                      })
                    }
                    appId={appId}
                    mashupId={items['Incyte Focus Areas - Research and Interest']}
                  />
                </Col>
                <Col span={8}>
                  <QSMashupObject
                    isTable
                    hideSearch
                    setCounter={({ rowCount: counter }) =>
                      setCounters((prevCount) => {
                        prevCount.interests = prevCount.interests || [];
                        prevCount.interests[2] = counter;
                        return prevCount;
                      })
                    }
                    appId={appId}
                    mashupId={items['Areas of Interest KPI - Research and Interest']}
                  />
                </Col>
              </Row>
            ),
          },
        },
        {
          menuItem: `Associations(${counters?.associations || 0})`,
          pane: {
            key: 'tab3',
            content: (
              <Row>
                <Col span={24}>
                  <QSMashupObject
                    isTable
                    hideSearch
                    tableProps={{
                      colOverrides: [
                        {
                          columns: [0],
                          hStyles: { width: "23rem" },
                        },
                        {
                          columns: [1],
                          hStyles: { width: "12rem" },
                        },
                        {
                          columns: [2],
                          hStyles: { width: "5.5rem" },
                        },
                        {
                          columns: [3],
                          hStyles: { width: "6rem" },
                        },
                        {
                          columns: [4],
                          hStyles: { width: "8rem" },
                        },
                      ]
                    }}
                    setCounter={({ rowCount: counter }) =>
                      setCounters((prevCount) => ({
                        ...prevCount,
                        associations: counter
                      }))
                    }
                    appId={appId}
                    mashupId={items['Associations Table - Bio']}
                  />
                </Col>
              </Row>
            ),
          },
        },
        {
          menuItem: `Editorial(${counters?.editorial || 0})`,
          pane: {
            key: 'tab4',
            content: (
              <Row>
                <Col span={24}>
                  <QSMashupObject
                    isTable
                    hideSearch
                    tableProps={{
                      colOverrides: [
                        {
                          columns: [0],
                          hStyles: { width: "34rem" },
                        },
                        {
                          columns: [1],
                          hStyles: { width: "15rem" },
                        },
                        {
                          columns: [2],
                          hStyles: { width: "5rem" },
                        },
                      ]
                    }}
                    setCounter={({ rowCount: counter }) =>
                      setCounters((prevCount) => ({
                        ...prevCount,
                        editorial: counter
                      }))
                    }
                    appId={appId}
                    mashupId={items['Editorial Table - Bio']}
                  />
                </Col>
              </Row>
            ),
          },
        },
      ];
      return (<>
        <Tabs className="bioTabs" type="card" size="large" centered>
          {panes.map(val => {
            return (
              <Tabs.TabPane forceRender tab={val.menuItem} key={val.pane.key}>
                {val.pane.content}
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </>
      );
    }
  },
  'Face to Face KPI': {
    content: (
      <QSMashupObject isText compact appId={appId} mashupId={items['Face to Face KPI']} elementId="mosaic_chart_21" />
    ),
  },
  'Face To Face KPI Box': {
    component: (props) => (
      <NavLinkWrapper to={`/HCP360/IncyteActivity/Interactions/${props.profId}`}>
        <KPIBox canClick customClass="kpibox kpi-blue">
          <Row style={{height:'100%', paddingBottom: '7px'}}>
            <Col span={8}>
              <QSMashupObject
                isText compact
                appId={appId}
                mashupId={items['Face to Face KPI']}
                progressIcon={ImgFaceToFace}
                contentClass="no-margin"
              />
            </Col>
            <Col span={2}>
              <AntDivider type="vertical" style={{height:"100%"}}></AntDivider>
            </Col>
            <Col span={14}>
              <ListAntd>
                <p style={{
                    fontSize: "1.07142857rem", 
                    marginTop:"0.2em", 
                    marginBottom:"0.6em", 
                    lineHeight:"1.0em"
                  }}>
                  Last Interaction
                </p>
                <QSMashupObject
                  appId={appId}
                  mashupId={items['Last Interaction Rep']}
                  customFormatter={({ val1 }) => {
                    let team, city, name, other;
                    const checkValues = /(^[A-Za-z0-9\s\-\,\/]*|\([A-Za-z0-9\s\-]*\)$)/g;
                    if (val1.indexOf(' - ') !== -1) {
                      [team, other] = val1
                        .split(' - ')
                        .map((item) => item.trim());
                      [city, name] = other
                        .match(checkValues)
                        .map((item) =>
                          item
                            .trim()
                            .replace(/(\(|\))/g, '')
                        );
                    } else {
                      [team, name] = val1
                        .match(checkValues)
                        .map((item) =>
                          item
                            .trim()
                            .replace(/(\(|\))/g, '')
                        );
                    }
                    return (
                      <ListAntd.Item className="no-padding">
                        <div style={{width:'100%'}}>
                          <div
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: 400,
                              lineHeight: "1.5rem",
                              color: "#005cab",
                              textTransform: "uppercase",
                              textAlign: "left"
                            }}
                          >
                            <span 
                              style={{
                                textAlign: 'left',
                                fontSize: '1.25rem',
                              }}
                            >
                                {!!team && (
                                  <div>
                                    {team}
                                  </div>
                                )}
                                {!!city && (
                                  <div>
                                    {city}
                                  </div>
                                )}
                                {!!name && (
                                  <div>
                                    {name}
                                  </div>
                                )}
                            </span>
                          </div>
                        </div>
                      </ListAntd.Item>
                    );
                  }}
                />
                <QSMashupObject isText compact asAntListItemWrap appId={appId} mashupId={items['Last Interaction Date']} />
              </ListAntd>
            </Col>
          </Row>
        </KPIBox>
      </NavLinkWrapper>
    ),
  },
  'Peer Interaction KPI': {
    content: (
      <QSMashupObject isText compact appId={appId} mashupId={items['Peer Interaction KPI']} elementId="mosaic_chart_22" />
    ),
  },
  'Peer Interaction KPI Box': {
    component: (props) => (
      <NavLinkWrapper to={`/HCP360/IncyteActivity/SpeakerPrograms/${props.profId}`}>
        <KPIBox id="kpiBoxPeerInteraction" canClick customClass="kpibox kpi-blue">
          <Row style={{height:"100%"}}>
            <Col span={8}>
              <QSMashupObject
                isText compact
                appId={appId}
                mashupId={items['Peer Interaction KPI']}
                progressIcon={ImgPeerInteraction}
                progressIconWidth="40px"
                contentClass="no-margin"
              />
            </Col>
            <Col span={2}>
              <AntDivider type="vertical" style={{height:"100%"}}></AntDivider>
            </Col>
            <Col span={14}>
              <ListAntd>
                <p 
                  style={{
                    fontSize: "1.07142857rem", 
                    marginTop:"0.2em", 
                    marginBottom:"-0.6em", 
                    lineHeight:"1.0em"
                  }}
                >
                  Speaker Programs
                </p>
                <QSMashupObject
                  isText compact
                  asAntListItem
                  appId={appId}
                  mashupId={items['Mosaic Overview']['Speaker Programs as Speaker']} />
                <QSMashupObject
                  isText compact
                  asAntListItem
                  appId={appId}
                  mashupId={items['Mosaic Overview']['Speaker Programs as Attendee']} />
              </ListAntd>
            </Col>
          </Row>
          <Typography.Title 
            className="kpibox--label"
            style={{
              position: "absolute",
              right:"1.2rem", 
              bottom:"0.5rem",
              color: "rgba(0, 0, 0, 0.6)"
            }}
          >R12Mo</Typography.Title>
        </KPIBox>
      </NavLinkWrapper>
    ),
  },
  'Digital Push KPI': {
    content: (
      <QSMashupObject isText compact appId={appId} mashupId={items['Digital Push KPI']} elementId="mosaic_chart_23" />
    ),
  },
  'Digital Push KPI Box': {
    component: (props) => (
      <NavLinkWrapper to={`/HCP360/IncyteActivity/MCE/${props.profId}`}>
        <KPIBox id="kpiBoxDigitalPush" canClick customClass="kpibox kpi-blue">
        <Row style={{height:"100%"}}>
            <Col span={8}>
              <QSMashupObject
                isText compact
                appId={appId}
                mashupId={items['Digital Push KPI']}
                progressIcon={ImgDigitalPush}
                contentClass="no-margin"
              />
            </Col>
            <Col span={2}>
              <AntDivider type="vertical" style={{height:"100%"}}></AntDivider>
            </Col>
            <Col span={14}>
              <ListAntd>
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Prod Strategy Email KPI']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Prod Strategy Textbooks KPI']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Prod Strategy Direct Mail KPI']} />
              </ListAntd>
            </Col>
          </Row>
          <Typography.Title 
            className="kpibox--label"
            style={{
              position: "absolute",
              right:"1.2rem", 
              bottom:"0.5rem",
              color: "rgba(0, 0, 0, 0.6)"
            }}
          >
            R12Mo
          </Typography.Title>
        </KPIBox>
      </NavLinkWrapper>
    ),
  },
  'Digital Pull KPI': {
    content: (
      <QSMashupObject isText compact appId={appId} mashupId={items['Digital Pull KPI']} elementId="mosaic_chart_24" />
    ),
  },
  'Digital Pull KPI Box': {
    component: (props) => (
      <NavLinkWrapper to={`/HCP360/IncyteActivity/MCE/${props.profId}`}>
       <KPIBox id="kpiBoxDigitalPull" canClick customClass="kpibox kpi-blue">
          <Row style={{height:"100%"}}>
            <Col span={8}>
              <QSMashupObject
                isText compact
                appId={appId}
                mashupId={items['Digital Pull KPI']}
                progressIcon={ImgDigitalPull}
                contentClass='no-margin'
              />
            </Col>
            <Col span={2}>
              <AntDivider type="vertical" style={{height:"100%"}}></AntDivider>
            </Col>
            <Col span={14}>
              <ListAntd>
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Digital Pull Engagements KPI']} />
              </ListAntd>
            </Col>
          </Row>
        </KPIBox>
      </NavLinkWrapper>
    ),
  },
  'Speaker Program KPI Box': {
    content: (
      <KPIBox customClass="kpibox kpi-blue" header="Speaker Program" mashupId={items['Speaker Program SubPage']} canClick={true}>
        <Row>
          <QSMashupObject isText compact horizontal appId={appId} mashupId={items['Speaker Program Speaker KPI']} elementId="mosaic_chart_27" />
        </Row>
        <Row>
          <QSMashupObject isText compact horizontal appId={appId} mashupId={items['Speaker Program Attendee KPI']} elementId="mosaic_chart_28" />
        </Row>
      </KPIBox>
    ),
  },
  'Speaker Program Speaker KPI': {
    content: (
      <QSMashupObject appId={appId} mashupId={items['Speaker Program Speaker KPI']} elementId="mosaic_chart_27" />
    ),
  },
  'Speaker Program Attendee KPI': {
    content: (
      <QSMashupObject appId={appId} mashupId={items['Speaker Program Attendee KPI']} elementId="mosaic_chart_28" />
    ),
  },
  'Prod Strategy Direct Mail KPI': {
    content: (
      <QSMashupObject appId={appId} mashupId={items['Prod Strategy Direct Mail KPI']} elementId="mosaic_chart_29" />
    ),
  },
  'Prod Strategy Email KPI': {
    content: (
      <QSMashupObject appId={appId} mashupId={items['Prod Strategy Email KPI']} elementId="mosaic_chart_30" />
    ),
  },
  'Prod Strategy Textbooks KPI': {
    content: (
      <QSMashupObject appId={appId} mashupId={items['Prod Strategy Textbooks KPI']} elementId="mosaic_chart_31" />
    ),
  },
  'Connections KPI': {
    content: (
      <QSMashupObject isText compact horizontal appId={appId} mashupId={items['Connections KPI']} elementId="mosaic_chart_32" contentClass="no-margin"/>
    ),
  },
  'Mosaic Search': {
    component: (props) => (<MosaicSearch app={props.app} showNPIFeature={true} showMyGeoControl={props.showMyGeoControl} searchFields={[fields['360 Search NPI']]} userField={fields['Masquerade User Id']} />),
  },
  'Incyte Activity - Speaker Programs': {
    component: (props) => (<SpeakerPrograms profId={props.profId} />),
  },
  'Incyte Activity - Alerts': {
    component: (props) => (<Alerts profId={props.profId} />),
  },
  'Incyte Activity - Barriers': {
    component: (props) => (<Barriers profId={props.profId} />),
  },
  'Incyte Activity - Affiliations': {
    component: (props) => (<Affiliations profId={props.profId} />),
  },
  'Clinicial Trials Incyte Activity': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        mashupId={items['Clinicial Trials Incyte Activity']}
        isTable
        {...props}
      />
    ),
  },
  'Investigator Trials Incyte Activity': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        mashupId={items['Investigator Trials Incyte Activity']}
        isTable
        {...props}
      />
    ),
  },
  'Adboards Incyte Activity': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        mashupId={items['Adboards Incyte Activity']}
        isTable
        {...props}
      />
    ),
  },
  'Publications Public Activity': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        mashupId={items['Publications Public Activity']}
        isTable
        {...props}
      />
    ),
  },
  'Clinical Trials Public Activity': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        mashupId={items['Clinical Trials Public Activity']}
        isTable
        {...props}
      />
    ),
  },
  'Presentations Public Activity': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        mashupId={items['Presentations Public Activity']}
        isTable
        {...props}
      />
    ),
  },
  // start
  'MCE Channel Header': {
    content: (
      <h3
        id="headerMCEChannels"
        style={{
          paddingLeft: '11px',
          display: 'flex',
          height: '100%',
          alignItems: 'flex-end',
          color: 'unset',
          fontWeight: 'bolder',
        }}>Channel Affinity</h3>
    ),
  },
  'MCE Benchmarks (90)': {
    content: (
      <h3
        id="headerMCE6M"
        style={{
          paddingLeft: '11px',
          display: 'flex',
          height: '100%',
          alignItems: 'flex-end',
          color: 'unset',
          fontWeight: 'bolder',
        }}>Incyte Benchmarks - 6 Months</h3>
    ),
  },
  'MCE Rolling Months': {
    content: (
      <h3
        id="headerMCER12M"
        style={{
          paddingLeft: '11px',
          display: 'flex',
          height: '100%',
          alignItems: 'flex-end',
          color: 'unset',
          fontWeight: 'bolder',
        }}>Rolling 12 Months</h3>
    ),
  },
  'Face To Face IA MCE KPI BOX': {
    content: (
      <KPIBox id="kpiBoxFaceToFace" customClass="kpibox kpi-blue">
        <QSMashupObject
          isText compact
          appId={appId}
          mashupId={items['Face to Face KPI']}
          progressIcon={ImgFaceToFace}
          contentClass="no-margin"
        />
      </KPIBox>
    ),
  },
  'Peer Interaction IA MCE KPI BOX': {
    content: (
      <KPIBox id="kpiBoxPeerInteraction" customClass="kpibox kpi-blue">
        <QSMashupObject
          isText compact
          appId={appId}
          mashupId={items['Peer Interaction KPI']}
          progressIcon={ImgPeerInteraction}
          contentClass="no-margin"
        />
      </KPIBox>
    ),
  },
  'Peer Interaction IA MCE STATS KPI BOX': {
    content: (
      <KPIBox customClass="kpibox kpi-blue">
        <Row>
          <Col span={11}>
            <QSMashupObject isText compact horizontal appId={appId} mashupId={items['Peer Interaction IA MCE Speaker Programs KPI']} />
          </Col>
          <Col>
            <AntDivider type="vertical" style={{height:"100%"}}></AntDivider>
          </Col>
          <Col span={11}>
            <QSMashupObject isText compact horizontal appId={appId} mashupId={items['Peer Interaction IA MCE Advisory Board KPI']} />
          </Col>
        </Row>
      </KPIBox>
    ),
  },
  'Digital Push IA MCE KPI BOX': {
    content: (
      <KPIBox id="kpiBoxDigitalPush" customClass="kpibox kpi-blue">
        <QSMashupObject
          isText compact
          appId={appId}
          mashupId={items['Digital Push KPI']}
          progressIcon={ImgDigitalPush}
          contentClass="no-margin"
        />
      </KPIBox>
    ),
  },
  'Digital Pull IA MCE KPI BOX': {
    content: (
      <KPIBox id="kpiBoxDigitalPull" customClass="kpibox kpi-blue">
        <QSMashupObject
          isText compact
          appId={appId}
          mashupId={items['Digital Pull KPI']}
          progressIcon={ImgDigitalPull}
          contentClass="no-margin"
        />
      </KPIBox>
    ),
  },
  'Face to Face Benchmarks KPI Box': {
    component: _ => {
      const displayVal = (val) => {
        const isYes = Number(val);
        const style: CSSProperties = isYes
          ? {
            fontWeight: 'bold',
            color: '#52c41a',
          }
          : {};    
        return <span style={{ fontSize: '1.2rem', color: '#595959', ...style }}>{isYes ? 'Yes' : 'No'}</span>
      };

      const virtualMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_F2F Virtual - 26W')
      const liveMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_F2F Live - 26W')

      const virtualData = useMemo(()=> {
        return virtualMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [virtualMashup?.table?.rows, virtualMashup?.table?.rows.length])

      const liveData = useMemo(()=> {
        return liveMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [liveMashup?.table?.rows, liveMashup?.table?.rows.length])

      const dataGrid = (
        <>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Jakafi</div>
            <div className='title-monjuvi row-content' style={{flex:1}}>Monjuvi/Pemazyre</div>
            <div className='title-jakafi row-content' style={{flex:1}}>Niktimvo</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>Virtual</div>
            <div className='row-content' style={{flex:1}}>{displayVal(virtualData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(virtualData?.[0]?.monpem)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(virtualData?.[0]?.niktimvo)}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>Live</div>
            <div className='row-content' style={{flex:1}}>{displayVal(liveData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(liveData?.[0]?.monpem)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(liveData?.[0]?.niktimvo)}</div>
          </div>
        </>
      )

      return (
        <KPIBox id="kpiBoxFaceToFace6M" customClass="kpibox kpi-blue" header="Calls">
          {(virtualData?.[0] && liveData?.[0]) ? dataGrid : (<Loader></Loader>)}
        </KPIBox>
      )
    }
  },
  'Peer Interaction Speaker Program Box': {
    component: ({profId}) => {
      const displayVal = (val) => {
        const isYes = Number(val);
        const style: CSSProperties = isYes
          ? {
            fontWeight: 'bold',
            color: '#52c41a',
          }
          : {};    
        return <span style={{ fontSize: '1.2rem', color: '#595959', ...style }}>{isYes ? 'Yes' : 'No'}</span>
      };

      const virtualMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Speaker Programs Virtual - 26W')
      const liveMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Speaker Programs Live - 26W')
      const nationalMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Speaker Programs National - 26W')

      const virtualData = useMemo(()=> {
        return virtualMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [virtualMashup?.table?.rows, virtualMashup?.table?.rows.length])

      const liveData = useMemo(()=> {
        return liveMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [liveMashup?.table?.rows, liveMashup?.table?.rows.length])

      const nationalData = useMemo(()=> {
        return nationalMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [nationalMashup?.table?.rows, nationalMashup?.table?.rows.length])

      const dataGrid = (
        <>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Jakafi</div>
            <div className='title-monjuvi row-content' style={{flex:1}}>Monjuvi/Pemazyre</div>
            <div className='title-jakafi row-content' style={{flex:1}}>Niktimvo</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>Virtual</div>
            <div className='row-content' style={{flex:1}}>{displayVal(virtualData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(virtualData?.[0]?.monpem)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(virtualData?.[0]?.niktimvo)}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>Live</div>
            <div className='row-content' style={{flex:1}}>{displayVal(liveData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(liveData?.[0]?.monpem)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(liveData?.[0]?.niktimvo)}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>National</div>
            <div className='row-content' style={{flex:1}}>{displayVal(nationalData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(nationalData?.[0]?.monpem)}</div>
            <div className='row-content' style={{flex:1}}>{displayVal(nationalData?.[0]?.niktimvo)}</div>
          </div>
        </>
      )

      return (
        <KPIBox id="kpiBoxFaceToFaceR12M" customClass="kpibox kpi-blue" header="Calls/Engagements" canClick to={`/HCP360/IncyteActivity/Interactions/${profId}`}>
          {(virtualData?.[0] && liveData?.[0]) ? dataGrid : (<Loader></Loader>)}
        </KPIBox>
      )
    }
  },
  'Face to Face Jakafi/Pemi - Monjuvi Digital Benchmark': {
    component: () => {    
      const ho1Mashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_F2F HO1 - 52W')
      const ho2Mashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_F2F HO2 - 52W')
      const ho3Mashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_F2F HO3 - 52W')
      const ocneMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_F2F OCNE - 52W')

      const ho1Data = useMemo(()=> {
        return ho1Mashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [ho1Mashup?.table?.rows, ho1Mashup?.table?.rows.length])

      const ho2Data = useMemo(()=> {
        return ho2Mashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [ho2Mashup?.table?.rows, ho2Mashup?.table?.rows.length])      

      const ho3Data = useMemo(()=> {
        return ho3Mashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [ho3Mashup?.table?.rows, ho3Mashup?.table?.rows.length])      

      const ocneData = useMemo(()=> {
        return ocneMashup?.table?.rows.map((row, rowIndex) => {
          return {
            all: row.cells[0].qText
          }
        })
      }, [ocneMashup?.table?.rows, ocneMashup?.table?.rows.length])      

      const dataGrid = (
        <>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Jakafi</div>
            <div className='title-monjuvi row-content' style={{flex:1}}>Monjuvi/Pemazyre</div>
            <div className='title-jakafi row-content' style={{flex:1}}>Niktimvo</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>HO1</div>
            <div className='row-content' style={{flex:1}}>{ho1Data?.[0]?.jakafi}</div>
            <div className='row-content' style={{flex:1}}>{ho1Data?.[0]?.monpem}</div>
            <div className='row-content' style={{flex:1}}>{ho1Data?.[0]?.niktimvo}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>HO2</div>
            <div className='row-content' style={{flex:1}}>{ho2Data?.[0]?.jakafi}</div>
            <div className='row-content' style={{flex:1}}>{ho2Data?.[0]?.monpem}</div>
            <div className='row-content' style={{flex:1}}>{ho2Data?.[0]?.niktimvo}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>HO3</div>
            <div className='row-content' style={{flex:1}}>{ho3Data?.[0]?.jakafi}</div>
            <div className='row-content' style={{flex:1}}>{ho3Data?.[0]?.monpem}</div>
            <div className='row-content' style={{flex:1}}>{ho3Data?.[0]?.niktimvo}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>All Brands</div>
            <div className='rowTitle' style={{flex:1}}></div>
            <div className='rowTitle' style={{flex:1}}></div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>OCNE</div>
            <div className='row-content' style={{flex:1}}>{ocneData?.[0]?.all}</div>
            <div className='row-content' style={{flex:1}}></div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
        </>
      )
      
      return (
        <KPIBox id="kpiBoxFaceToFaceR12M" customClass="kpibox kpi-blue" header="Calls">
          {(ho1Data?.[0] && ho2Data?.[0] && ho3Data?.[0] && ocneData?.[0]) ? dataGrid : (<Loader></Loader>)}
        </KPIBox>
      )
    }
  },
  'Speaker Program Jakafi/Pemi - Monjuvi KPI Box': {
    component: (props) => {
      const navLinkButton = (val, brand) => {
        return (
          <div style={{ height: '100%', width: '100%', marginRight: '5px', marginLeft: '5px'}}>
            <NavlinkButton 
              brand={brand}
              address={`/HCP360/IncyteActivity/SpeakerPrograms/${props.profId}`}
              text = {val}
            />
          </div>
        )
      }

      const programsMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Speaker Programs - 52W')
      const boardsMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Ad Boards - 52W')

      const programsData = useMemo(()=> {
        return programsMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [programsMashup?.table?.rows, programsMashup?.table?.rows.length])

      const boardsData = useMemo(()=> {
        return boardsMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [boardsMashup?.table?.rows, boardsMashup?.table?.rows.length])

      const dataGrid = (
        <>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Jakafi</div>
            <div className='title-monjuvi row-content' style={{flex:1}}>Monjuvi/Pemazyre</div>
            <div className='title-jakafi row-content' style={{flex:1}}>Niktimvo</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>Speaker Programs</div>
            <div className='row-content' style={{flex:1}}>{navLinkButton(programsData?.[0]?.jakafi, 'JAKAFI')}</div>
            <div className='row-content' style={{flex:1}}>{navLinkButton(programsData?.[0]?.monpem, 'MONJUVI')}</div>
            <div className='row-content' style={{flex:1}}>{navLinkButton(programsData?.[0]?.niktimvo, 'NIKTIMVO')}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>Ad Boards</div>
            <div className='row-content' style={{flex:1}}>{navLinkButton(boardsData?.[0]?.jakafi, 'JAKAFI')}</div>
            <div className='row-content' style={{flex:1}}>{navLinkButton(boardsData?.[0]?.monpem, 'MONJUVI')}</div>
            <div className='row-content' style={{flex:1}}>{navLinkButton(boardsData?.[0]?.niktimvo, 'NIKTIMVO')}</div>
          </div>
        </>
      )

      return (
        <KPIBox id="kpiBoxSpeakerProgramsR12M" customClass="kpibox kpi-blue">
          {(programsData?.[0] && boardsData?.[0]) ? dataGrid : (<Loader></Loader>)}
        </KPIBox>
      )
    }
  },
  'Digital Push Jakafi/Pemi - Monjuvi Box': {
    component: _ => {
      const getCheck = (val: string) => {
        if (val == null) return null
        let content = (
          <div style={{width: '100%', marginLeft: '5px', marginRight: '5px'}}>
            <Tag style={{ fontSize: '1.5rem', width: '100%', textAlign: 'center' }} color={val.toUpperCase() === 'SENT' ? 'processing' : 'success'}>
              {val}
            </Tag>
          </div>
        )
        return val === '-' ? '' : content;
      }

      const rteMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push RTE - 26W')
      const hqMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push HQ - 26W')
      const resourceMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push Resource Send - 26W')

      const rteData = useMemo(()=> {
        return rteMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [rteMashup?.table?.rows, rteMashup?.table?.rows.length])

      const hqData = useMemo(()=> {
        return hqMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [hqMashup?.table?.rows, hqMashup?.table?.rows.length])

      const resourceData = useMemo(()=> {
        return resourceMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [resourceMashup?.table?.rows, resourceMashup?.table?.rows.length])

      const dataGrid = (
        <>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Jakafi</div>
            <div className='title-monjuvi row-content' style={{flex:1}}>Monjuvi/Pemazyre</div>
            <div className='title-jakafi row-content' style={{flex:1}}>Niktimvo</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>RTE</div>
            <div className='row-content' style={{flex:1}}>{getCheck(rteData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}>{getCheck(rteData?.[0]?.monpem)}</div>
            <div className='row-content' style={{flex:1}}>{getCheck(rteData?.[0]?.niktimvo)}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>HQ</div>
            <div className='row-content' style={{flex:1}}>{getCheck(hqData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}>{getCheck(hqData?.[0]?.monpem)}</div>
            <div className='row-content' style={{flex:1}}>{getCheck(hqData?.[0]?.niktimvo)}</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>Resource</div>
            <div className='row-content' style={{flex:1}}>{getCheck(resourceData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}>{getCheck(resourceData?.[0]?.monpem)}</div>
            <div className='row-content' style={{flex:1}}>{getCheck(resourceData?.[0]?.niktimvo)}</div>
          </div>
        </>
      )

      return (
        <KPIBox id="kpiBoxSpeakerProgramsR12M" customClass="kpibox kpi-blue">
          {(rteData?.[0] && hqData?.[0] && resourceData?.[0]) ? dataGrid : (<Loader></Loader>)}
        </KPIBox>
      )
    }
  },
  'Digital Push Jakafi/Pemi - Monjuvi KPI Box': {
    component: (_) => {
      const metadata = useRecoilValue(QSAppMetadataFamily(appId));
      const { IDsTable: ids } = metadata;

      const rteSentMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push RTE Sent - 52W')
      const rteViewedMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push RTE Viewed - 52W')
      const hqSentMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push HQ Sent - 52W')
      const hqViewedMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push HQ Viewed - 52W')
      const resourceSentMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push Resource Sent - 52W')
      const resourceViewedMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Push Resource Viewed - 52W')

      const rteSentData = useMemo(()=> {
        return rteSentMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [rteSentMashup?.table?.rows, rteSentMashup?.table?.rows.length])

      const rteViewedData = useMemo(()=> {
        return rteViewedMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [rteViewedMashup?.table?.rows, rteViewedMashup?.table?.rows.length])

      const hqSentData = useMemo(()=> {
        return hqSentMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [hqSentMashup?.table?.rows, hqSentMashup?.table?.rows.length])

      const hqViewedData = useMemo(()=> {
        return hqViewedMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [hqViewedMashup?.table?.rows, hqViewedMashup?.table?.rows.length])

      const resourceSentData = useMemo(()=> {
        return resourceSentMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [resourceSentMashup?.table?.rows, resourceSentMashup?.table?.rows.length])

      const resourceViewedData = useMemo(()=> {
        return resourceViewedMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            monpem: row.cells[1].qText,
            niktimvo: row.cells[2].qText,
          }
        })
      }, [resourceViewedMashup?.table?.rows, resourceViewedMashup?.table?.rows.length])

      const dataGrid = (
        <>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Jakafi</div>
            <div className='title-monjuvi row-content' style={{flex:1}}>Monjuvi/Pemazyre</div>
            <div className='title-jakafi row-content' style={{flex:1}}>Niktimvo</div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>RTE</div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "JAKAFI"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push RTE - Jak 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    }, {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    }],
                }}
                text = {`${rteSentData?.[0]?.jakafi} (${rteViewedData?.[0]?.jakafi})`}
              />
            </div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "MONJUVI"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push - RTE - Mon/Pem 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    }, {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    }],
                }}
                text = {`${rteSentData?.[0]?.monpem} (${rteViewedData?.[0]?.monpem})`}
              />
            </div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "NIKTIMVO"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push RTE - Nik 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    }, {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    }],
                }}
                text = {`${rteSentData?.[0]?.niktimvo} (${rteViewedData?.[0]?.niktimvo})`}
              />
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>HQ</div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "JAKAFI"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push - HQ  - Jak 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 1, 2],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [3],
                      hStyles: { width: '8rem' },
                    }, {
                      columns: [4, 5],
                      hStyles: { width: '22rem' },
                    }]
                }}
                text = {`${hqSentData?.[0]?.jakafi} (${hqViewedData?.[0]?.jakafi})`}
              />
            </div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "MONJUVI"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push - HQ  - Mon/Pem 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 1, 2],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [3],
                      hStyles: { width: '8rem' },
                    }, {
                      columns: [4, 5],
                      hStyles: { width: '22rem' },
                    }]
                }}
                text = {`${hqSentData?.[0]?.monpem} (${hqViewedData?.[0]?.monpem})`}
              />
            </div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "NIKTIMVO"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push - HQ  - Nik 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 1, 2],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [3],
                      hStyles: { width: '8rem' },
                    }, {
                      columns: [4, 5],
                      hStyles: { width: '22rem' },
                    }]
                }}
                text = {`${hqSentData?.[0]?.niktimvo} (${hqViewedData?.[0]?.niktimvo})`}
              />
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='rowTitle' style={{flex:1}}>Resource</div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "JAKAFI"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push -  Resource Send - Jak 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    }, {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    }]
                }}
                text = {`${resourceSentData?.[0]?.jakafi} (${resourceViewedData?.[0]?.jakafi})`}
              />
            </div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "MONJUVI"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push -  Resource Send - Mon/Pem 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    }, {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    }]
                }}
                text = {`${resourceSentData?.[0]?.monpem} (${resourceViewedData?.[0]?.monpem})`}
              />
            </div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "NIKTIMVO"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Push -  Resource Send - Nik 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [{
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    }, {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    }, {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    }]
                }}
                text = {`${resourceSentData?.[0]?.niktimvo} (${resourceViewedData?.[0]?.niktimvo})`}
              />
            </div>
          </div>
          <div style={{ display: 'grid', gridTemplateRows: '6fr 5fr', paddingTop: '3px' }}>
            <div style={{ gridRow: '1', justifySelf: 'center', fontSize: '1.2rem' }}>
              Delivered (Viewed*)
            </div>
            <div style={{ gridRow: '2', justifySelf: 'end' }}>
              <span style={{ fontSize: '1.2rem' }}>*</span>
              <span style={{ fontSize: '1rem' }}>Viewed numbers do <strong>not</strong> include blocked by Apple</span>
            </div>
          </div>
        </>
      )

      return (
        <KPIBox id="kpiBoxDigitalPullR12M" customClass="kpibox kpi-blue">
          {(rteSentData?.[0] && rteViewedData?.[0] && hqSentData?.[0] && hqViewedData?.[0] && resourceSentData?.[0] && resourceViewedData?.[0]) ? dataGrid : (<Loader></Loader>)}
        </KPIBox>
      )
    }
  },
  'Digital Pull Jakafi/Pemi - Monjuvi Box': {
    component: _ => {
      const displayVal = (val) => {
        const isYes = Number(val);
        const style: CSSProperties = isYes
          ? {
            fontWeight: 'bold',
            color: '#52c41a',
          }
          : {};
        return <span style={{ fontSize: '1.2rem', color: '#595959', ...style }}>{isYes ? 'Yes' : 'No'}</span>
      };

      const digitalPullMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Pull - 26W')
      
      const digitalPullData = useMemo(()=> {
        return digitalPullMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            pemazyre: row.cells[1].qText,
            monjuvi: row.cells[2].qText,
            niktimvo: row.cells[3].qText,
          }
        })
      }, [digitalPullMashup?.table?.rows, digitalPullMashup?.table?.rows.length])

      const dataGrid = (
        <>
          <div style={{display: 'flex'}}>
            <div className='row-content' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Jakafi</div>
            <div className='row-content' style={{flex:1}}>{displayVal(digitalPullData?.[0]?.jakafi)}</div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='row-content' style={{flex:1}}></div>
            <div className='title-pemazyre row-content' style={{flex:1}}>Pemazyre</div>
            <div className='row-content' style={{flex:1}}>{displayVal(digitalPullData?.[0]?.pemazyre)}</div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='row-content' style={{flex:1}}></div>
            <div className='title-monjuvi row-content' style={{flex:1}}>Monjuvi</div>
            <div className='row-content' style={{flex:1}}>{displayVal(digitalPullData?.[0]?.monjuvi)}</div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='row-content' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Niktimvo</div>
            <div className='row-content' style={{flex:1}}>{displayVal(digitalPullData?.[0]?.niktimvo)}</div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
        </>
      )

      return (
        <KPIBox id="kpiBoxDigitalPull6M" customClass="kpibox kpi-blue" header="Engagements">
          {(digitalPullData?.[0]) ? dataGrid : (<Loader></Loader>)}
        </KPIBox>
      )
    }
  },
  'Digital Pull Jakafi/Pemi - Monjuvi KPI Box': {
    component: (_) => {
      const metadata = useRecoilValue(QSAppMetadataFamily(appId));
      const { IDsTable: ids } = metadata;

      const digitalPullMashup = useMashup(appId, 'Mosaic_HCP 360 - MCE_Digital Pull - 52W')
      
      const digitalPullData = useMemo(()=> {
        return digitalPullMashup?.table?.rows.map((row, rowIndex) => {
          return {
            jakafi: row.cells[0].qText,
            pemazyre: row.cells[1].qText,
            monjuvi: row.cells[2].qText,
            niktimvo: row.cells[3].qText,
          }
        })
      }, [digitalPullMashup?.table?.rows, digitalPullMashup?.table?.rows.length])

      const dataGrid = (
        <>
          <div style={{display: 'flex'}}>
            <div className='row-content' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Jakafi</div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "JAKAFI"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Pull - Jak 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [
                    {
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    },
                    {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    },
                    {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    },
                  ],
                }}
                text = {digitalPullData?.[0]?.jakafi}
              />
            </div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='row-content' style={{flex:1}}></div>
            <div className='title-pemazyre row-content' style={{flex:1}}>Pemazyre</div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "PEMAZYRE"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Pull - Pem 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [
                    {
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    },
                    {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    },
                    {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    },
                  ],
                }}
                text = {digitalPullData?.[0]?.pemazyre}
              />
            </div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='row-content' style={{flex:1}}></div>
            <div className='title-monjuvi row-content' style={{flex:1}}>Monjuvi</div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "MONJUVI"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Pull - Mon 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [
                    {
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    },
                    {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    },
                    {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    },
                  ],
                }}
                text = {digitalPullData?.[0]?.monjuvi}
              />
            </div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
          <div style={{display: 'flex'}}>
            <div className='row-content' style={{flex:1}}></div>
            <div className='title-jakafi row-content' style={{flex:1}}>Niktimvo</div>
            <div className='row-content' style={{flex:1}}>
              <SubpageButton
                brand = "NIKTIMVO"
                mashupId={ids.get('Mosaic_HCP 360 -MCE_Digital Pull - Nik 12 mo Table')?.id}
                tableProps = {{
                  colOverrides: [
                    {
                      columns: [0, 2, 3],
                      hStyles: { width: '7rem' },
                    },
                    {
                      columns: [1, 5, 6],
                      hStyles: { width: '22rem' },
                    },
                    {
                      columns: [4],
                      hStyles: { width: '8rem' },
                    },
                  ],
                }}
                text = {digitalPullData?.[0]?.niktimvo}
              />
            </div>
            <div className='row-content' style={{flex:1}}></div>
          </div>
        </>
      )

      return (
        <KPIBox id="kpiBoxDigitalPull6M" customClass="kpibox kpi-blue" header="Engagements">
          {(digitalPullData?.[0]) ? dataGrid : (<Loader></Loader>)}
        </KPIBox>
      )
    }
  },
  'Claims KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-green" header="Dx Claims">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Dx Claims DLBCL']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['MorphoSys Target Type']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Bottles KPI Box - Jakafi': {
    component: (props) => (
      <KPIBox id="kpiBoxBottlesJakafi" customClass="kpibox kpi-green" header="Bottles">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['Bottles R12MO KPI']} />
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['Bottles C13W KPI']} />
          <QSMashupObject
            isText compact
            asAntListItem
            appId={appId}
            mashupId={items['Mosaic Overview']['JAKAFI']['Bottles YTD KPI']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'HCP Landscape KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-orange" header="HCP Landscape">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Speaker']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['External Expert']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Investigator']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Reg. CAR-T Expert']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Policy Experience']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'NPP Alerts KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-purple" header="NPP Alerts - LTD" canClick to={props.to}>
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['RTE Opens']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['HQ Emails CTA Clicked']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['3rd Party Email CTA Clicked']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Web Pull']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Copay Card KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-blue" header="Copay Card Utilization">
        <ListAntd style={{alignContent:'center'}} className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Monjuvi LTD']} />
          <AntDivider type="horizontal" style={{marginTop:"12px", marginBottom:"-4px"}}/>
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Jakafi (Y/N)']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Treatment KPI Box': {
    content: (
      <KPIBox customClass="kpibox kpi-blue" header="Treatment">
        <ListAntd style={{alignContent:'center'}} className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['Treatment First Rx']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['Treatment Last Rx']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Textbooks Box': {
    content: (
      <KPIBox customClass="kpibox kpi-blue" header="Textbooks">
        <ListAntd style={{alignContent:'center'}} className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Overview_Textbook LTD Count']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Overview_Textbook YTD Count']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Connections KPI Box': {
    component: (props) => (
      <KPIBox canClick to={props.to} customClass="kpibox kpi-blue" header="Connections">
        <Row>
          <QSMashupObject isText heroValue={18} appId={appId} mashupId={items['Connections KPI']} contentClass="no-margin"/>
        </Row>
      </KPIBox>
    ),
  },
  'Monjuvi Connections KPI Box': {
    component: (props) => (
      <KPIBox canClick to={props.to} customClass="kpibox kpi-blue" header="Connections">
        <Row>
          <QSMashupObject isText heroValue={18} appId={appId} mashupId={items['Connections KPI']} contentClass="no-margin"/>
        </Row>
      </KPIBox>
    ),
  },
  'Hub Enrollment KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-orange" header="MyMISSIONSupport">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Monjuvi - R12M']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Monjuvi - LTD']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Monjuvi - Last Ship Date']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Diagnosis Claims Quintile KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-blue" header="Diagnosis Claims Quintile">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['Diagnosis Claims Quintile MF']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['Diagnosis Claims Quintile PV']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['Diagnosis Claims Quintile GVHD']} />
        </ListAntd>
        <Typography.Title 
          className="kpibox--label" 
          style={{
            position: "absolute",
            right:"1.2rem", 
            bottom:"0.5rem",
            color: "rgba(0, 0, 0, 0.6)"
          }}
        >1=Lowest; 5=Highest</Typography.Title>
      </KPIBox>
    ),
  },
  'HCO Info KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-purple" header="HCO Info">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Account Tier']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['First Shipment Date']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Last Shipment Date']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'HCO Vials KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-purple" header="HCO Vials">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Vials - R12M']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Vials - C13W']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Vials - YTD']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Monjuvi Experience KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-blue" header="Monjuvi Experience">
        <Row>
          <QSMashupObject isText heroValue="small" appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Adoption']} contentClass="no-margin"/>
        </Row>
        <Row>
          <Col>
            <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['First Detected Date']} />
          </Col>
        </Row>
      </KPIBox>
    ),
  },
  'SP MF/PV Split KPI Box': {
    content: (
      <KPIBox customClass="kpibox kpi-blue" header="SP MF/PV Split">
        <QSMashupObject stackedChart100 appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['SP MF/PV Split Chart']} />
      </KPIBox>
    ),
  },
  'Jakafi Landscape KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-jakafi-green" header="Jakafi Landscape">
          <Row style={{height:'100%'}}>
            <Col span={8} style={{ padding: '0 1rem 0 0' }}>
              <ListAntd className="listantd-hcp360-spacing">
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Target Type']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Speaker (Y/N)']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Last Rx Date']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['R12M Utilization (Y/N)']} />
              </ListAntd>
            </Col>
            <Col span={1}>
              <AntDivider type="vertical" style={{height:"85%"}}></AntDivider>
            </Col>
            <Col span={7}>
              <span className="landscape-col">SP Patients</span>
              <ListAntd className="no-padding">
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['SP Patients Active KPI']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['SP Patients R12Mo New KPI']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['SP Patients YTD New KPI']} />
              </ListAntd>
            </Col>
            <Col span={1}>
              <AntDivider type="vertical" style={{height:"85%"}}></AntDivider>
            </Col>
            <Col span={7}>
              <span className="landscape-col">Bottles</span>
              <ListAntd className="no-padding">
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Bottles R12MO KPI']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Bottles C13W KPI']} />
                <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['MONJUVI']['Bottles YTD KPI']} />
              </ListAntd>
            </Col>
          </Row>
      </KPIBox>
    ),
  },
  'Monjuvi Landscape KPI Box': {
    component: (props) => (
      <KPIBox customClass="kpibox kpi-orange" header="Monjuvi Landscape">
        <ListAntd className="listantd-hcp360-spacing">
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['Target Type']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['Speaker (Y/N)']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['DLBCL EE']} />
          <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['Last Mon Engagement Date']} />
        </ListAntd>
      </KPIBox>
    ),
  },
  'Pemazyre Landscape KPI Box': {
    content: (
      <KPIBox customClass="kpibox kpi-orange" header="Pemazyre Landscape">
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <ListAntd>
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['Target Type']} />
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['Speaker (Y/N)']} />
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']["CCA EE"]} />
            </ListAntd>
          </Col>
          <Col span={12}>
            <ListAntd>
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['Last Rx Date']} />
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['Active Patient Count']} />
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['Pemi use in last 12 months']} />
            </ListAntd>
          </Col>
        </Row>
        <AntDivider type="horizontal" style={{ marginTop: "0px", marginBottom: "8px" }}/>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <ListAntd>
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['CCA Patient Potential']} />
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['CCA Foundation Member Y/N']} />
              <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['Last PEM Engagement Date']} />
            </ListAntd>
          </Col>
          <Col span={12}>
          <ListAntd style={{padding: "0px 0px 7px 0px"}}>
              <QSMashupObject isText compact asAntListItemWrap appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['CCA Testing Behavior']} />
              <QSMashupObject isText compact asAntListItem hasChildren appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['CCA Investigator']}/>
              <QSMashupObject
                  style={{padding:'2px'}}
                  compact
                  appId={appId}
                  mashupId={items['Mosaic Overview']['PEMAZYRE']['CCA Investigator INCY and EXT']}
                  customFormatter={({title1, val1, title2, val2}) => {
                    return <ListAntd.Item style={{display: "block", padding:"0px"}}>
                      <Row 
                        align={"bottom"} 
                        justify={"space-between"}
                        style={{
                          fontSize: '1em',
                          fontWeight: 700,
                          color: "#595959",
                          textTransform: "uppercase",
                          textAlign: "center",
                          lineHeight: "0.5rem"
                        }} 
                      >
                        <Col offset={4} style={{fontSize:"1rem"}} >{(title1)}</Col>
                        <Col 
                          style={{
                            fontWeight: 400,
                            fontSize: "1.5rem",
                            color: "#005cab",
                            textTransform: "uppercase",
                            textAlign: "center",
                          }}
                        >{(val1)}</Col>
                      </Row>
                      <Row align={"bottom"} 
                        justify={"space-between"}
                        style={{ 
                          fontSize: '1em',
                          fontWeight: 700,
                          color: "#595959",
                          textTransform: "uppercase",
                          textAlign: "center",
                          lineHeight:1.75,
                          marginBottom:"-0.5em"
                        }} 
                      >
                        <Col offset={4} style={{fontSize:"1rem"}} >{(title2)}</Col>
                        <Col
                          style={{
                            fontWeight: 400,
                            fontSize: "1.5rem",
                            color: "#005cab",
                            textTransform: "uppercase",
                            textAlign: "center"
                          }}
                        >{(val2)}</Col>
                      </Row>
                    </ListAntd.Item>;
                  }}
              />
              <QSMashupObject isText style={{padding:"0px"}} compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['PEMAZYRE']['GEM CIS Treater']} />
            </ListAntd>
          </Col>
        </Row>
      </KPIBox>
    ),
  },
  'Connections Top Table': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        elementId="connectionsTopTable"
        mashupId={items['Connections']['Top Table']}
        isTable
        {...props}
      />
    ),
  },
  'Connections Bottom Table': {
    component: ({ activeTeam, ...props }) => (
      <QSMashupObject
        key={`connectionsTable${activeTeam}`}
        appId={appId}
        elementId="connectionsBottomTable"
        mashupId={items['Connections']['Bottom Table'][activeTeam]}
        exportId={items['Connections']['Exports TableId'][activeTeam]}
        isTable
        {...props}
      />
    ),
  },
  'CMS Spend Industry Spend': {
    component: (props) => (
      <QSMashupObject
        contentStyle={{ height: '100%', width: '100%', padding: '2px' }}
        appId={appId}
        elementId="cmsSpendIndustrySpendTable"
        mashupId={items['CMS Spend Charts']['Industry Spend']}
        {...props}
      />
    )
  },
  'CMS Spend By Year Chart': {
    component: (props) => (
      <QSMashupObject
        contentStyle={{ height: '100%', width: '100%', padding: '2px' }}
        appId={appId}
        elementId="cmsSpendByYearChart"
        barChart
        mashupId={items['CMS Spend Charts']['CMS Spend by Year']}
      />
    )
  },
  'Disclosures Table Overview': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        mashupId={items['Disclosures Table']}
        isTable
        {...props}
      />
    )
  },
  'Grants Table Overview': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        mashupId={items['Grants Table']}
        isTable
        {...props}
      />
    )
  },
  'Summary of Connections': {
    component: (props) => (
      <QSMashupObject
        appId={appId}
        elementId="summaryOfConnectionsTable"
        mashupId={items['Connections']['Summary of Types']}
        isTable
        {...props}
      />
    )
  },
  'Prof Connection Details': {
    component: ({ tempProfId, connectedProfRef, ...props }) => {
      return (
        <>
          {tempProfId && (
            <div
              ref={connectedProfRef}
              className={`full-height scroll-vertical`}
            >
              <HCP360State profId={tempProfId}>
                <ProfKPIGroup
                  profId={tempProfId}
                  {...props}
                  connected
                />
              </HCP360State>
            </div>
          ) || (
              <ProfKPIGroup
                placeholder
                {...props}
                connected
              />
            )}
        </>
      );
    }
  },
}

export default HCP360;