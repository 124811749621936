import { QSAppMetadataFamily, QlikUser as QlikUserAtom } from "@trinity-incyte/recoil";
import { KPIBox, PDRPMessage, QSMashupObject, Redacted } from "@trinity-incyte/ui";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import Config from "../../Config";
import ImgDigitalPull from '../../../assets/Images/digital_pull.png';
import ImgDigitalPush from '../../../assets/Images/digital_push.png';
import ImgFaceToFace from '../../../assets/Images/face_to_face.png';
import ImgPeerInteractionIcon from '../../../assets/Images/peer_to_peer.png';
import { List as ListAntd } from 'antd';
import QSTableTiles from "../Other/QSTableTiles";
import BarrierSegment from "../Other/BarrierSegment";
import { HCP360Context } from "@trinity-incyte/context";
import { StarFilled } from "@ant-design/icons";
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";
import { useMashup, useQSGeneric } from "@trinity-incyte/hooks";
import { BasicTableColumnRenderer } from "libs/ui/src/lib/Qlik/qscustom-table/basic-table/basic-table";
import QSField from "libs/ui/src/lib/Qlik/qsfield/qsfield";
declare const Mosaic: MosaicGlobal;

const config = Config.Qlik.Prof360;
const { appId } = config.ids;
let dateValue = "";



export const HCP360JakafiOverview = {
    'JAKAFI Bottles Box': {
        component: (props) => { 
            const QlikUser = useRecoilValue(QlikUserAtom);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids, FieldsTable: fields } = metadata;
            const hcp360Context = useContext(HCP360Context);
            const pdrpOptout = hcp360Context.profData?.pdrpOptout;
            const app = Mosaic.Qlik.app[appId];
            let definition = {
                dateValue: {qStringExpression: `=Only(${fields.get('Mosaic_HCP 360_ION Data Month')?.id})`},
                qInfo: {qId: `hcp360JBBIonDate_${Date.now()}`}
            };
            //Warning: This will not get new data when profId changes; we don't need new data here though 
            //   since IONDate should be the same for all Profs
            const ionDateObject = useQSGeneric({definition, app})
            const ionDate = ionDateObject?.dateValue ?? ""

            if (pdrpOptout == null) {
                //The state of the pdrp hasn't been determined yet, so don't render anything
                return (<></>);
            }
            if (pdrpOptout === 'Y') {
                //The prof has opted out of having their data visible, so don't show it
                return (<PDRPMessage customClass="kpibox kpi-threesixty" />)
            }

            const suppressSalesData = QlikUser?.suppressSalesData;

            return (
                <KPIBox customClass="kpibox kpi-threesixty">

                    {suppressSalesData ? <Redacted titleProp={"Sales Volume"} /> :
                        <>
                            <div style={{display:'flex', width:'100%'}}>
                                <div className="header-threesixty" style={{width:'40%',}}>{"Sales Volume"}</div>
                                {(ionDate) && (<div style={{width:'60%', color:"#666", fontWeight:'normal', textAlign:'right'}}>Monthly data as of {ionDate}</div>)}
                            </div>
                            <QSMashupObject
                                appId={appId}
                                customClass="basicTableFour"
                                mashupId={ids.get('Mosaic_HCP 360 - Overview_Bottles')?.id}
                                isBasicTable
                                compactMargin
                                compact
                                {...props}
                            />
                        </>
                    }                
                </KPIBox>
            )
        }   
    },
    'JAKAFI Treatment LTD': {
    component: (props) => {
        const QlikUser = useRecoilValue(QlikUserAtom);
        const metadata = useRecoilValue(QSAppMetadataFamily(appId));
        const { IDsTable: ids } = metadata;

        const hcp360Context = useContext(HCP360Context);
        const pdrpOptout = hcp360Context.profData?.pdrpOptout;

        const suppressSalesData = QlikUser?.suppressSalesData;

        if (pdrpOptout == null) {
            //The state of the pdrp hasn't been determined yet, so don't render anything
            return (<></>);
        }
        if (pdrpOptout === 'Y') {
            //The prof has opted out of having their data visible, so don't show it
            return (<PDRPMessage customClass="kpibox kpi-threesixty" />)
        }
        return (
            <KPIBox customClass="kpibox kpi-threesixty">
                {suppressSalesData ? <Redacted titleProp={"Treatment LTD"} /> :
                    <>
                        <div className="header-threesixty">{"Treatment LTD"}</div>
                        <div style={{textAlign:'center', width:'100%'}}>
                            <QSMashupObject isText compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Jakafi First Rx')?.id} />
                            <hr style={{width:'50%', border:'#eee 1px solid', margin:'6px auto'}}/>
                            <QSMashupObject isText compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Jakafi Last Rx')?.id} />
                        </div>
                    </>
                }
            </KPIBox>
        )}
    },
    'JAKAFI Barriers Concept': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
                <KPIBox customClass="kpibox kpi-orange">
                <div className="header-threesixty" style={{color:'#FF8000'}}>{"Barriers"}</div>
                <div style={{padding:`0px 2px 0px 2px`,  width:'100%'}}>
                    <BarrierSegment appId={appId} mashupName={'Mosaic_HCP 360_Main - Jakafi_Barrier Segmentation'} customContainerClass="barrierContainerClass" customClass="barrierSegments" />
                </div>
                </KPIBox>
            )}
        },
    'JAKAFI Multi Channel Engagement': {
    component: (props) => {
        const metadata = useRecoilValue(QSAppMetadataFamily(appId));
        const { IDsTable: ids } = metadata;
        return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/MCE/${props.profId}`}>
            <div className="header-threesixty-linked header-threesixty-linked-table">{"Multi Channel Engagement"}</div>
            <div style={{display:'flex', width:'100%', margin:'8px 0px 0px 0px', flexWrap:'wrap'}}>
                <div style={{width:'50%', padding:'0'}}>
                <QSMashupObject isText progressIconLeft={ImgFaceToFace} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Face to Face AFF KPI')?.id} />
                </div>
                <div style={{width:'50%', padding:'0'}}>
                <QSMashupObject isText progressIconLeft={ImgPeerInteractionIcon} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Peer Interaction KPI')?.id} />          
                </div>
                <div style={{width:'50%', padding:'0'}}>
                <QSMashupObject isText progressIconLeft={ImgDigitalPush} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Digital Push KPI')?.id} />          
                </div>
                <div style={{width:'50%', padding:'0'}}>
                <QSMashupObject isText progressIconLeft={ImgDigitalPull} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Digital Pull KPI')?.id} />          
                </div>
            </div>
            </KPIBox>
        )}
    },
    'JAKAFI Diagnosis Claims Quintile': {
    component: (props) => {
        const metadata = useRecoilValue(QSAppMetadataFamily(appId));
        const { IDsTable: ids } = metadata;
        const { FieldsTable: fields } = metadata;
    return (
        <KPIBox customClass="kpibox kpi-threesixty">
        <div style={{textAlign:'left'}}>
            <div className="header-threesixty" style={{flex: 1, display: 'flex'}}>
                <div style={{flex: 1}}>{"Diagnosis Claims Quintile"}</div>
                <div style={{color:'#666', fontWeight:'normal'}}>1-Lowest, 5-Highest</div>
            </div>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}></div>
                <div style={{textAlign: "right"}}>
                    <div style={{fontWeight: 700}}>MPNs</div>
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 -Overview_MF Quintile KPI'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 -Overview_PV Quintile KPI'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                </div>
                <div style={{flex: 1}}></div>
                <div style={{textAlign: "right"}}>
                    <div style={{fontWeight: 700}}>GVHD</div>
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 - Overview_Acute GVHD Quintile'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 - Overview_Chronic GVHD Quintile'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 - Overview_Total GVHD Quintile'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                </div>
                <div style={{flex: 1}}></div>
            </div>
        </div>
        </KPIBox>
    )}
    },
    'JAKAFI Connections': {
    component: (props) => {        
        const mashupData = useMashup(appId, 'Mosaic_HCP 360 - Overview_HCP Connections KPI');
        const numConnections = mashupData?.table?.rows[0]?.cells[0]?.qText ?? "-";
        return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/Connections/${props.profId}`}>
                <div className="header-threesixty text-center">{"Connections"}</div>
                <div className="text-center qsKPIValue">{numConnections}</div>
            </KPIBox>
        )
    }
    },
    'JAKAFI Affiliations': {
    component: (props) => {
        const mashupData = useMashup(appId, 'Mosaic_HCP 360 - Overview_HCP Affiliations KPI');
        const numAffiliations = mashupData?.table?.rows[0]?.cells[0]?.qText ?? "-";
        return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/Affiliations/${props.profId}`}>
                <div className="header-threesixty text-center">{"Affiliations"}</div>
                <div className="text-center qsKPIValue">{numAffiliations}</div>
            </KPIBox>
        )
    }
    },
    'JAKAFI Alerts': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/Alerts/${props.profId}`}>
                <div className="header-threesixty-linked">{"Alerts"}</div>
                <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%'}}>
                    <QSTableTiles appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Jakafi Alert Tiles')?.id} labelColumnId={0} valueColumnId={1} />
                </div>
            </KPIBox>
            )
        }
    },
    'JAKAFI Barriers': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids, FieldsTable: fields } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/Barriers/${props.profId}`}>
                <div className="header-threesixty-linked" style={{margin:'4px 0px 8px 0px'}}>
                    {"Barriers"}
                </div>
                <QSMashupObject 
                    compact
                    compactMargin
                    customClass="basicTable"
                    isBasicTable
                    appId={appId}
                    mashupId={ids.get('Mosaic_HCP 360 - Overview_Jakafi Barrier Tiles')?.id}
                    columnRenderers={new Map<string, BasicTableColumnRenderer>([
                        ["Indication", { cellRenderer: (cellInfo, columns, data) => {
                            return (
                                <td key={`dataCell_Indication`} style={{ }}>
                                    {data.RecentFlag === "Y" ? <StarFilled style={{ color: 'gold', marginTop: '1px', marginRight: '0.5em', stroke: 'red', strokeWidth: '40' }} /> : ""}
                                    {cellInfo}
                                </td>
                            )
                        } }],
                        ["RecentFlag", { headerRenderer: (cellInfo, columns, index) => { }, cellRenderer: (cellInfo, columns, data) => {} }]
                    ])}
                />
            </KPIBox>
            )
        }
    },
    'JAKAFI Marketing Campaign Journeys': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/Interactions/${props.profId}?type=NPP`}>
                <div style={{display:'flex'}}>
                    <div className="header-threesixty-linked header-threesixty-linked-table" style={{width:'50%'}}>{"Marketing Campaign Journey"}</div>
                    <div style={{margin:'4px 0px 8px 8px', width:'50%', textAlign:'right'}}>Active Journey in Current Quarter</div>
                </div>
                <QSMashupObject
                appId={appId}
                objectKey='Jakafi_Marketing_Campaign_Journeys'
                customClass="basicTable_marketingCampaignJourney"
                mashupId={ids.get('Mosaic_HCP 360_Jakafi Marketing Campaign Journeys')?.id}
                isBasicTable
                compact
                compactMargin
                {...props}
            />
            </KPIBox>
        )}
        },
        'JAKAFI HCP Activity': {
    component: (props) => {
        const metadata = useRecoilValue(QSAppMetadataFamily(appId));
        const { IDsTable: ids } = metadata;
        return (
        <KPIBox canClick to={`/HCP360/IncyteActivity/Interactions/${props.profId}`} customClass="kpibox kpi-threesixty">
            <div className="header-threesixty-linked header-threesixty-linked-table">{"HCP Activity"}</div>
            <QSMashupObject
            appId={appId}
            objectKey='UpVLTw'
            customClass="basicTable"
            mashupId={ids.get('Mosaic_HCP 360 - Overview_HCP Most Recent Activity')?.id}
            isBasicTable
            compact
            compactMargin
            {...props}
        />
        </KPIBox>
    )}
    },
    // For Phase II of 360
    // 'JAKAFI BMT Details': {
    //   component: (props) => (
    //     <KPIBox customClass="kpibox kpi-threesixty">
    //       {"BMT Details"}
    //     </KPIBox>
    //   )
    // },
    'JAKAFI MPN Landscape': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty">
            <div className="header-threesixty">{"MPN Landscape"}</div>
            <QSMashupObject
                    appId={appId}
                    objectKey='Mosaic_HCP_360_Overview_MPN_Landscape'
                    customClass="basicTable"
                    mashupId={ids.get('Mosaic_HCP 360 - Overview_MPN Landscape')?.id}
                    isBasicTable
                    compact
                    compactMargin
                    {...props}
                />
            </KPIBox>
        )}
    },
    'JAKAFI GVHD Landscape': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty">
            <div className="header-threesixty">{"GVHD Landscape"}</div>
            <QSMashupObject
                    appId={appId}
                    objectKey='Mosaic_HCP 360_GVHD_Landscape'
                    customClass="basicTable"
                    mashupId={ids.get('Mosaic_HCP 360_GVHD Landscape')?.id}
                    isBasicTable
                    compact
                    compactMargin
                    {...props}
                />
            </KPIBox>
        )}
    },
    'JAKAFI SP Patients': {
        component: (props) => {
            const QlikUser = useRecoilValue(QlikUserAtom);
            const { appId, items, fields } = config.ids;

            const suppressSalesData = QlikUser?.suppressSalesData;
            const canClick = !suppressSalesData;

            return (
            <KPIBox id="kpiBoxSPPatients" canClick={canClick} mashupId={config.ids.items['Mosaic Overview']['Patients Popup Table']} minimalHeader customClass="kpibox kpi-threesixty" header="">
                {suppressSalesData ? <Redacted titleProp={"SP Patients"}/> :
                    <>
                        <div className="header-threesixty-linked header-threesixty-linked-table">{"SP Patients"}</div>
                        <ListAntd className="listantd-hcp360-spacing" style={{margin: '0px 6px 0px 6px'}}>
                            <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['SP Patients Active KPI']} elementId="mosaic_chart_7" />
                            <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['SP Patients R12Mo New KPI']} elementId="mosaic_chart_8" />
                            <QSMashupObject isText compact asAntListItem appId={appId} mashupId={items['Mosaic Overview']['JAKAFI']['SP Patients YTD New KPI']} elementId="mosaic_chart_9" />
                        </ListAntd>
                    </>
                }
            </KPIBox>
        )}
    },
}

export default HCP360JakafiOverview;