import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

function reviver(key, value) {
  if(typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

export interface useAppMetadataProps {
  Config: any;
  ids: any;
  set_QSAppMetadata: (metadata: object) => void;
  environment: any;
}

export function useAppMetadata ({ Config, ids, set_QSAppMetadata, environment }: useAppMetadataProps) {
  const loadMetadataPromise = new Promise<void>((resolveMetadata, rejectMetadata) => {
    const metadata = {
      FieldsTable: null,
      IDsTable: null,
      DimensionsList: null,
      MeasuresList: null,
      FieldsList: null,
      AlertsDismissals: null
    };

    const folderSuffix = environment.environmentName == "Local" || environment.environmentName == "Review" ? "Test" : environment.environmentName

    const criticalDataFetchItems = [
      new Promise(async (resolve, reject) => {
        const filename = ids.items['tablesFileName']
        const response = await fetch(`https://${environment.Qlik_config_host}/Content/Lineage${folderSuffix}/${filename}?${Config.App.buildDate}`, { credentials: 'include' })        
        const tablesMap = JSON.parse(await response.text(), reviver)
        metadata.IDsTable = tablesMap
        resolve(true)
      }),
      new Promise(async (resolve, reject) => {
        const filename = ids.items['fieldsFileName']
        // ereiamjh
        const response = await fetch(`https://${environment.Qlik_config_host}/Content/Lineage${folderSuffix}/${filename}?${Config.App.buildDate}`, { credentials: 'include' })
        const fieldsMap = JSON.parse(await response.text(), reviver)
        metadata.FieldsTable = fieldsMap
        resolve(true)
      }),
    ]

    // Set the QS metadata for this particular app
    Promise.allSettled(criticalDataFetchItems)
      .then(() => {
        set_QSAppMetadata(metadata);
        resolveMetadata()
      });

  })

  return loadMetadataPromise;
}

export default useAppMetadata;