import React, { useEffect, useState, useContext } from 'react';
import { useTransition, animated } from 'react-spring';
import {
	Select,
	Row,
	Dropdown,
	Button,
	Menu,
	Typography,
	Space,
	Divider,
	Result,
	Layout,
	Col,
} from 'antd';
import {
	DownOutlined,
	CheckCircleFilled,
	CloseSquareOutlined,
	DownloadOutlined,
} from '@ant-design/icons';
import pptxgen from "pptxgenjs";
import DateSelectionButtons from '../Groups/DateSelectionButtons';
import { QSMashupObject, TabbedContainer } from '@trinity-incyte/ui';
import { Loader } from 'libs/ui/src/lib/Other/loader/loader';
import {
	useQSListbox,
	useQSGeneric
} from '@trinity-incyte/hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	closeModalSelector,
	openModalSelector,
	QlikUser as QlikUserAtom,
	QSAppMetadataFamily,
	QlikLastModified as QlikLastModifiedAtom,
	regionSelectionCount as regionSelectionCountAtom,
	activeTeam as activeTeamAtom,
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import Flavor from 'apps/mosaic/src/assets/Powerpoint/Incyte_Flavor.png';
import logo from 'apps/mosaic/src/assets/Powerpoint/Incyte_Logo.png';
import whiteRectangle from 'apps/mosaic/src/assets/Powerpoint/white_rectangle.png';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import './RegionAnalysis.scss'
import { getTeamBrands } from '@trinity-incyte/utils'

declare const Mosaic: MosaicGlobal;
declare var window: any;
const { Sider } = Layout;

const size = (window.innerHeight < 800) ? 'small' : 'middle';

const RegionFilter = ({ config, fieldName }) => {
	const Config = useContext(ConfigContext);
	const set_regionSelectionCount = useSetRecoilState(regionSelectionCountAtom);

	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'Region',
	});

	const filterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
			return acc;
		}, []),
		options: listbox.map((val, ind) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number[]) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
				return acc;
			}, []);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);
				cell.unselect();
			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				let newListBoxes = []; // array for qlik
				listbox.forEach((listBoxItem) => {
					if ((listBoxItem.qElemNumber === changedCellElem[0]) || (listBoxItem.qState == Config.Qlik.qStateValues.SELECTED)) {
						newListBoxes.push({ qText: listBoxItem.qText });
					}
				});
				const appId = config.ids.appId;
				Mosaic.Qlik.app[appId].field(fieldName).selectValues(newListBoxes);
			}
		},
		placeholder: 'Select Regions...',
		maxTagCount: 'responsive' as const,
	};

	set_regionSelectionCount(filterProps.value.length);

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label filter-label'>Region</div>
				<div className='filter-reset'>
					<Button type="link" onClick={() => { listbox[0].clear() }}>Reset</Button>
				</div>
			</div>
			<Row gutter={[2, 16]}>
				<Select {...filterProps} />
			</Row>
		</>
	);

	return content;
};

const TerritoryFilter = ({ config, fieldName }) => {
	const Config = useContext(ConfigContext);
	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'Territory',
	});

	const filterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
			return acc;
		}, []),
		options: listbox.map((val, ind) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number[]) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
				return acc;
			}, []);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);
				cell.unselect();
			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);

				let newListBoxes = []; // array for qlik
				listbox.forEach((listBoxItem) => {
					if ((listBoxItem.qElemNumber === changedCellElem[0]) || (listBoxItem.qState == Config.Qlik.qStateValues.SELECTED)) {
						newListBoxes.push({ qText: listBoxItem.qText });
					}
				});
				const appId = config.ids.appId;
				Mosaic.Qlik.app[appId].field(fieldName).selectValues(newListBoxes);
			}
		},
		placeholder: 'Select Territories...',
		maxTagCount: 'responsive' as const,
	};

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label'>Territory</div>
				<div className='filter-reset'>
					<Button type="link" onClick={() => { listbox[0].clear() }}>Reset</Button>
				</div>
			</div>
			<Row gutter={[2, 16]}>
				<Select {...filterProps} />
			</Row>
		</>
	);

	return content;
};

const RegionAnalysis = (props) => {
	const { config } = props;
	const Config = useContext(ConfigContext);
	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const activeTeam = useRecoilValue(activeTeamAtom);
	const regionSelectionCount = useRecoilValue(regionSelectionCountAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [regionAnalysisTables, set_regionAnalysisTables] = useState([]);
	const openModal = useSetRecoilState(openModalSelector);
	const closeModal = useSetRecoilState(closeModalSelector);
	const [showFilters, set_showFilters] = useState<boolean>(true);
	const [collapsed, set_collapsed] = useState(false);
	const [resizer, set_resizer] = useState(() => () => undefined);
	const defaultActiveChart = QlikUser.empType == 'OBD' ? 0 : 3; // OBD only has 3 charts total, so set default to the first chart
	const [activeChart, set_activeChart] = useState<number>(defaultActiveChart);
	const [activeBrand, set_activeBrand] = useState<number>(0);
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const transitions = useTransition(showFilters, null, {
		from: { position: 'relative', opacity: 0.5 },
		enter: { opacity: 1 },
		leave: { opacity: 0.5 },
	});
	const fieldNameGeneric = useQSGeneric({
		definition: {
			qInfo: {qId: `regionAnalysis_${Date.now()}`},
			region: {
				qStringExpression:
					`=IF(FSD.TeamID=11, 'DSF.TM11.DIST_NAME_WITH_MGR'
				, IF(FSD.TeamID=12, 'DSF.TM12.DIST_NAME_WITH_MGR'
				, IF(FSD.TeamID=21, 'DSF.TM21.DIST_NAME_WITH_MGR'
				)))`
			},
			territory: {
				qStringExpression:
					`=IF(FSD.TeamID=11, 'DSF.TM11.TERR_NAME_WITH_REP'
				, IF(FSD.TeamID=12, 'DSF.TM12.TERR_NAME_WITH_REP'
				, IF(FSD.TeamID=21, 'DSF.TM21.TERR_NAME_WITH_REP'
				)))`
			},
		},
		app
	});

	const [fieldNameRegion, set_fieldNameRegion] = useState(null);
	const [fieldNameTerritory, set_fieldNameTerritory] = useState(null);
	const checkAccessToNation = () => {
		const {
			REGIONANALYSIS: { showNation } = {
				showNation: 'N',
			},
		} = QlikUser?.features || {};
		return (showNation === 'Y');
	};
	const checkAccessToRegion = () => {
		const {
			REGIONANALYSIS: { showRegion } = {
				showRegion: 'N',
			},
		} = QlikUser?.features || {};
		return (showRegion === 'Y');
	};
	const [isOneRegion, set_isOneRegion] = useState<boolean>(!checkAccessToNation());

	useEffect(() => {
		if (!checkAccessToNation()) return;

		const varName = 'isOneRegion';
		const getVariable = () => {
			app.variable.getByName(varName).then(function (model) {
				model.getLayout().then(layout => {
					set_isOneRegion((layout.qText) || (regionSelectionCount === 1) ? true : false);
				})
			}, function (errorObject) {
				if (errorObject?.error?.code === 2) {
					app.variable
						.createSessionVariable({ qName: varName, qDefinition: `=ONLY([${fields.get('Mosaic_Field Sales_Region HO1')?.id}])` })
						.then(() => {
							getVariable();
						});
				}
			});
		};

		getVariable();
	}, [QlikLastModified, regionSelectionCount]);

	useEffect(() => {
		const locationField = app.field('LocationSelectionDesc');
		if (isOneRegion) {
			locationField.selectValues(['Territory']);
		} else {
			locationField.selectValues(['Region']);
		}
	}, [isOneRegion, activeTeam]);

	useEffect(() => {
		if (!fieldNameGeneric) return;
		set_fieldNameRegion(fieldNameGeneric.region);
		set_fieldNameTerritory(fieldNameGeneric.territory);
	}, [JSON.stringify(fieldNameGeneric)]);

	let tables;

	useEffect(() => {
		if (activeTeam === "HO3") {
			if (activeBrand === 0) {
				let HO3Tables = [];
				if (checkAccessToNation()) {
					HO3Tables.push({ ...ids.get('Mosaic_HO3 Region Analysis_table_Region Summary') });
					HO3Tables.push({ ...ids.get('Mosaic_HO3 Region Analysis_table_Region Contribution') });
				}
				if (checkAccessToNation() || checkAccessToRegion()) {
					HO3Tables.push({ ...ids.get('Mosaic_HO3 Region Analysis_table_Territory Summary') });
					HO3Tables.push({ ...ids.get('Mosaic_HO3 Region Analysis_table_Territory Contribution') });
				}
				tables = [...HO3Tables,
				{ ...ids.get('Mosaic_HO3 Region Analysis_table_Calls') },
				].map(({ id, description }, index) => ({
					id,
					key: index.toString(),
					label: description,
				}));
			} else if (activeBrand === 1) {
				tables = [
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_table_Territory Summary') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_table_Region Summary') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_piechart_Region Contribution') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_piechart_Territory Contribution') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_linechart_Normalized Rx Trend') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_linechart_Factored Rx Trend') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_linechart_Direct Sales % of Total') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_linechart_New SP Prescribers') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_linechart_New SP Patients') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_linechart_Prescribers') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_linechart_Patients') },
					{ ...ids.get('Mosaic_Region Analysis - Pemazyre_linechart_Calls') },
				].map(({ id, description }, index) => ({
					id,
					key: index.toString(),
					label: description,
				}));
			}
		} else {
			tables = [
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_table_Region Summary') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_piechart_Region Contribution') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_table_Territory Summary') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_piechart_Territory Contribution') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_linechart_Normalized Rx Trend') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_table_Factored Rx Trend') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_linechart_Direct Sales % of Total') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_linechart_New SP Prescribers') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_linechart_New SP Patients') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_linechart_Prescribers') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_linechart_Patients') },
				{ ...ids.get('Mosaic_Region Analysis - Jakafi_linechart_Calls') },
			].map(({ id, description }, index) => ({
				id,
				key: index.toString(),
				label: description,
			}));
		}

		if (activeChart > tables.length) {
			set_activeChart(0); // reset the menu to the first item if the list changes
		}

		let filteredTables = tables.filter((table) => {
			return table.label !== 'Region Summary' && table.label !== 'Region Contribution'
		});

		if (!checkAccessToNation() || !checkAccessToRegion()) {
			set_regionAnalysisTables(filteredTables);
		} else {
			set_regionAnalysisTables(tables);
		}

	}, [activeTeam, activeBrand]);


	useEffect(() => {
		set_activeBrand(0);
	}, [activeTeam])

	const filterFields = {
		area: 'DSF.TM11.AREA_NAME_WITH_MGR',
		region: fieldNameRegion,
		territory: fieldNameTerritory,
		salesChannel: `=[PM.PATIENT_CHANNEL]`,
		salesTargetType: `=[DP.PROF_TARGET_TYPE_TM11]`,
		salesStrength: `=[DPROD.JAKAFI_STRENGTH]`,
	};

	const { rows: listboxSalesChannel } = useQSListbox({
		config,
		definition: [filterFields.salesChannel],
		title: 'Sales Channel',
	});

	const { rows: listboxSalesTarget } = useQSListbox({
		config,
		definition: [filterFields.salesTargetType],
		title: 'Sales Target Type',
	});

	const { rows: listboxSalesStrength } = useQSListbox({
		config,
		definition: [filterFields.salesStrength],
		title: 'Sales Strength',
	});

	const subFilterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		maxTagCount: 'responsive' as const,
		value: null,
		options: null,
		onChange: null,
		placeholder: null,
	};

	if (regionAnalysisTables.length > 0 && regionAnalysisTables[activeChart].label === 'Normalized Rx Trend') {
		subFilterProps.placeholder = 'Filter Sales...';
		subFilterProps.value = [].concat(
			listboxSalesChannel.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(`channel_${curr.qElemNumber}`);
				return acc;
			}, []),
			listboxSalesStrength.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(`strength_${curr.qElemNumber}`);
				return acc;
			}, []),
			listboxSalesTarget.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(`target_${curr.qElemNumber}`);
				return acc;
			}, []),
		);

		subFilterProps.options = [].concat(
			{ label: 'By Channel', disabled: true },
			listboxSalesChannel.map((val) => ({
				label: val.qText,
				value: `channel_${val.qElemNumber}`,
				disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
			})).filter((option) => { return option.disabled === false }),
			{ label: 'By Strength', disabled: true },
			listboxSalesStrength.map((val) => ({
				label: val.qText,
				value: `strength_${val.qElemNumber}`,
				disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
			})).filter((option) => { return option.disabled === false }),
			{ label: 'By Target', disabled: true },
			listboxSalesTarget.map((val) => ({
				label: val.qText,
				value: `target_${val.qElemNumber}`,
				disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
			})).filter((option) => { return option.disabled === false }),
		);

		subFilterProps.onChange = (newValue: string[]) => {
			const currentSelectedElems = [].concat(
				listboxSalesChannel.reduce((acc, curr) => {
					if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(`channel_${curr.qElemNumber}`);
					return acc;
				}, []),
				listboxSalesStrength.reduce((acc, curr) => {
					if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(`strength_${curr.qElemNumber}`);
					return acc;
				}, []),
				listboxSalesTarget.reduce((acc, curr) => {
					if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(`target_${curr.qElemNumber}`);
					return acc;
				}, []),
			);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listboxSalesChannel[0].clear();
					listboxSalesStrength[0].clear();
					listboxSalesTarget[0].clear();
					return;
				}

				const changedCellType = changedCellElem[0].split('_')[0];
				const changedCellValue = parseInt(changedCellElem[0].split('_')[1], 10);
				let listbox;
				if (changedCellType === 'channel') {
					listbox = listboxSalesChannel;
				} else if (changedCellType === 'strength') {
					listbox = listboxSalesStrength;
				} else if (changedCellType === 'target') {
					listbox = listboxSalesTarget;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellValue) acc = curr;
					return acc;
				}, null);
				cell.unselect();

			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listboxSalesChannel[0].clear();
					listboxSalesStrength[0].clear();
					listboxSalesTarget[0].clear();
					return;
				}

				const changedCellType = changedCellElem[0].split('_')[0];
				const changedCellValue = parseInt(changedCellElem[0].split('_')[1], 10);
				let listbox;
				if (changedCellType === 'channel') {
					listbox = listboxSalesChannel;
				} else if (changedCellType === 'strength') {
					listbox = listboxSalesStrength;
				} else if (changedCellType === 'target') {
					listbox = listboxSalesTarget;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellValue) acc = curr;
					return acc;
				}, null);
				cell.select();
			} else { // This shouldn't happen

			}
		};
	}

	function handleChange(value) {
		set_activeChart(parseInt(value.key, 10));
	}

	function handleBrandChange(value) {
		set_activeBrand(parseInt(value.key, 10));
	}

	interface SlidesProps {
		id: string;
		label?: string;
	}

	const onClickExportPPT = () => {
		window.analytics?.track('Button Clicked', { text: 'Download All', context: 'Download all in Field Sales Region Analysis' });
		let downloadButton;
		let newDate = new Date;
		const slides: SlidesProps[] = [];

		for (let ii = 0; ii < regionAnalysisTables.length; ii += 1) {
			if (Array.isArray(regionAnalysisTables[ii].id)) {
				for (let ij = 0; ij < regionAnalysisTables[ii].id.length; ij += 1) {
					slides.push({
						id: regionAnalysisTables[ii].id[ij],
						label: `${regionAnalysisTables[ii].label} - ${ij + 1}`,
					});
				}
			} else {
				slides.push({
					id: regionAnalysisTables[ii].id,
					label: regionAnalysisTables[ii].label
				})
			}
		}

		const getTheObjects = new Promise((resolve, reject) => {
			const vizPromiseArray = slides.map(({ id }) => {
				return app.visualization.get(id);
			});

			Promise.allSettled(vizPromiseArray)
				.then(results => {
					resolve(results);
				})
				.catch((err) => reject(err))
				.finally(() => {
					console.log('getTheObjects.finally');
				})
		});

		getTheObjects
			.then((results: any[]) => {
				openModal({
					content:
						<div>
							<Result
								title="Exporting Data"
								subTitle="Data is being exported. Please wait."
								extra={
									<div>
										<div style={{ height: "10%", marginBottom: "1em" }}>
											<Loader size="small" />
										</div>
										<br />
										<Button style={{ backgroundColor: "red", borderColor: "red", color: "white" }} icon={<CloseSquareOutlined />} onClick={closeModal}>
											Close
										</Button>
									</div>
								}
							>
							</Result>
						</div>,
					size: 'small',
				});
				let numComplete = 0;
				const exportUrlArray = results.map((viz) => {
					return new Promise((resolve, reject) => {
						let isCompleted = false;

						const getImageData = (url, qViz) => {
							fetch(url, { credentials: 'include' })
								.then(response => response.blob())
								.then(function (blob) {
									const reader = new FileReader();
									reader.onload = function () {
										numComplete += 1;
										resolve({
											title: qViz?.model?.layout?.title,
											type: qViz?.model?.layout?.visualization,
											imgData: this.result.toString(),
										});
									};
									reader.readAsDataURL(blob);
								});
						};

						let imgSettings;
						if (viz.value?.model?.enigmaModel?.layout?.visualization === "piechart") {
							imgSettings = { format: 'png', height: '768', width: '1024' };
						} else {
							imgSettings = { format: 'png', height: '750', width: '1250' };
						}

						viz.value.exportImg(imgSettings)
							.then((url) => { getImageData(url, viz.value) })
							.catch((err) => { console.warn(err); reject(err); })
							.finally(() => { isCompleted = true });

						setTimeout(() => {
							if (!isCompleted) {
								viz.value.exportImg(imgSettings)
									.then((url) => { getImageData(url, viz.value) })
									.catch((err) => { console.warn(err); reject(err); })
									.finally(() => { isCompleted = true, console.log('got all exportImg') });
								setTimeout(() => {
									if (!isCompleted) {
										reject('Timed OUT');
									}
								}, 30000);
							}
						}, 30000);
					});
				});

				Promise.allSettled(exportUrlArray)
					.then((a: any[]) => {
						// Set up the initital PPT
						let pptx = new pptxgen();
						pptx.defineSlideMaster({
							title: "ParentSlide",
							objects: [
								{ image: { x: 8.1, y: 0.15, h: "50%", w: "17.5%", path: Flavor } },
								{ image: { x: 0.5, y: "90%", h: "6%", w: "5%", path: logo } }
							],
						});

						for (let ii = 0; ii < a.length; ii += 1) {
							if (!a[ii].value.title) continue;
							const {
								title,
								type,
								imgData,
							} = a[ii].value;
							let slide = pptx.addSlide({ masterName: "ParentSlide" });

							if (type === "piechart") {
								slide.addImage({ data: imgData, x: '12.5%', y: '7%', h: '90%', w: '67.5%' });
								slide.addImage({ path: whiteRectangle, x: '12.5%', y: '7%', h: '3%', w: '30%' })
							} else {
								slide.addImage({ data: imgData, x: '6%', y: '8%', h: '80%', w: '76%' });
								slide.addImage({ path: whiteRectangle, x: '6%', y: '8%', h: '3%', w: '30%' })
							}

							slide.addText(title, {
								x: '4.5%',
								y: 0.2,
								color: "005CAB",
								fontFace: "Tahoma (Headings)",
								fontSize: 26,
								underline: { style: 'sng' },
								align: 'left'
							});
						}
						downloadButton = (
							<Button
								style={{ backgroundColor: "#005cab", borderColor: "#005cab", color: "white" }}
								onClick={(e) => {
									pptx.writeFile({ fileName: `RegionAnalysisReport - ${newDate.toISOString().substring(0, 10)}.pptx` });
									closeModal(e);
								}}
							>
								<DownloadOutlined />
								Download
							</Button>
						);
						openModal({
							content:
								<div>
									<Result
										status="success"
										title="Download Data"
										subTitle="Data is ready for download."

										extra={[
											<Space size="middle">
												{downloadButton}
												<Button style={{ color: "white", backgroundColor: "red", borderColor: "red" }} onClick={closeModal}>
													<CloseSquareOutlined />
													Close
												</Button>
											</Space>
										]}
									>
									</Result>
								</div>,
							size: 'small',
						});
					})
					.catch(err => console.warn(err))
					.finally(() => {
						console.log('exportUrls allSettled .finally');
					});
			})
			.catch(err => console.warn(err));
	};

	const menu = (
		<Menu onClick={handleChange}>
			{regionAnalysisTables.map(({ label }, ind) => (
				<Menu.Item key={ind} data-label={label}>
					{(ind === activeChart) && (
						<CheckCircleFilled style={{ color: 'var(--brand)', marginRight: '4px' }} />
					)}
					{label}
				</Menu.Item>
			))}
		</Menu>
	);

	var brands = getTeamBrands(Config.App.teamToBrand, activeTeam);
	if (activeTeam==="HO3") {
		brands = brands.filter(brand => brand!=="ZYNYZ")
	}
	const brandMenu = (
		<Menu onClick={handleBrandChange}>
			{brands.map((brand, ind) => (
				<Menu.Item key={ind} data-label={brand}>
					{(ind === activeBrand) && (
						<CheckCircleFilled style={{ color: 'var(--brand)', marginRight: '4px' }} />
					)}
					{brand}
				</Menu.Item>
			))}
		</Menu>
	);

	let cardContent;

	if (regionAnalysisTables.length > 0) {
		if (regionAnalysisTables[activeChart].label === 'Region Summary') {
			let regionSummaryMashupId = '';
			let regionStatsMashupId = '';

			if (activeTeam === "HO3") {
				if (activeBrand === 0) {
					regionSummaryMashupId = ids.get('Mosaic_HO3 Region Analysis_table_Region Summary')?.id;
					regionStatsMashupId = ids.get('Mosaic_HO3 Region Analysis_table_Region Stats')?.id;
				} else if (activeBrand === 1) {
					regionSummaryMashupId = ids.get('Mosaic_Region Analysis - Pemazyre_table_Region Summary')?.id;
					regionStatsMashupId = ids.get('Mosaic_Region Analysis - Pemazyre_table_Region Stats')?.id;
				}
			} else {
				regionSummaryMashupId = ids.get('Mosaic_Region Analysis - Jakafi_table_Region Summary')?.id;
				regionStatsMashupId = ids.get('Mosaic_Region Analysis - Jakafi_table_Region Stats')?.id;
			}
			cardContent = (
				<TabbedContainer
					panes={[{
						name: 'Summary',
						content: (
							<QSMashupObject
								appId={appId}
								mashupId={regionSummaryMashupId}
								key={regionSummaryMashupId}
								showExports
								exportAsImg
								exportAsPPT
								isTable
								showTotals="top"
								contentStyle={{ height: '65vh' }}
								tableProps={{
									colOverrides: [
										{
											columns: [0],
											hStyles: { width: "8rem" },
										}, {
											columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
											hStyles: { width: "6rem" },
										},
									]
								}}
							/>
						),
					}, {
						name: 'Stats',
						content: (
							<QSMashupObject
								appId={appId}
								mashupId={regionStatsMashupId}
								key={regionStatsMashupId}
								isSubtitleRed
								showExports
								exportAsImg
								exportAsPPT
								isTable
								contentStyle={{ height: '65vh' }}
								tableProps={{
									colOverrides: [
										{
											columns: [0],
											hStyles: { width: "8rem" },
										}, {
											columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
											hStyles: { width: "6rem" },
										},
									]
								}}
							/>
						)
					}]}
				/>
			);
		} else if (regionAnalysisTables[activeChart].label === 'Territory Summary') {
			let territorySummaryMashupId = '';
			let territoryStatsMashupId = '';
			if (activeTeam === "HO3") {
				if (activeBrand === 0) {
					territorySummaryMashupId = ids.get('Mosaic_HO3 Region Analysis_table_Territory Summary')?.id;
					territoryStatsMashupId = ids.get('Mosaic_HO3 Region Analysis_table_Territory Stats')?.id;
				} else if (activeBrand === 1) {
					territorySummaryMashupId = ids.get('Mosaic_Region Analysis - Pemazyre_table_Territory Summary')?.id;
					territoryStatsMashupId = ids.get('Mosaic_Region Analysis - Pemazyre_table_Territory Stats')?.id;
				}
			} else {
				territorySummaryMashupId = ids.get('Mosaic_Region Analysis - Jakafi_table_Territory Summary')?.id;
				territoryStatsMashupId = ids.get('Mosaic_Region Analysis - Jakafi_table_Territory Stats')?.id;
			}
			cardContent = (
				<TabbedContainer
					panes={[{
						name: 'Summary',
						content: (
							<QSMashupObject
								appId={appId}
								mashupId={territorySummaryMashupId}
								key={territorySummaryMashupId}
								showExports
								exportAsImg
								exportAsPPT
								isTable
								showTotals="top"
								contentStyle={{ height: '65vh' }}
								tableProps={{
									colOverrides: [
										{
											columns: [0],
											hStyles: { width: "8rem" },
										}, {
											columns: [1],
											hStyles: { width: "14rem" },
										}, {
											columns: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
											hStyles: { width: "7rem" },
										},
									]
								}}
							/>
						),
					}, {
						name: 'Stats',
						content: (
							<QSMashupObject
								appId={appId}
								mashupId={territoryStatsMashupId}
								key={territoryStatsMashupId}
								showExports
								exportAsImg
								exportAsPPT
								isSubtitleRed
								isTable
								showTotals="top"
								contentStyle={{ height: '65vh' }}
								tableProps={{
									colOverrides: [
										{
											columns: [0],
											hStyles: { width: "8rem" },
										}, {
											columns: [1],
											hStyles: { width: "14rem" },
										}, {
											columns: [2, 3, 4, 5, 6, 7, 8],
											hStyles: { width: "7rem" },
										},
									]
								}}
							/>
						)
					}]}
				/>
			);
		} else {
			let isTable = regionAnalysisTables[activeChart].label === 'Region Stats' ||
				regionAnalysisTables[activeChart].label === 'Territory Stats';
			cardContent = (
				<QSMashupObject
					appId={appId}
					mashupId={regionAnalysisTables[activeChart]?.id}
					key={`comparison_chart_${regionAnalysisTables[activeChart]?.id}`}
					contentStyle={{ height: '70vh' }}
					exportAsPPT={(true)}
					isTable={isTable}
					showExports={(
						regionAnalysisTables[activeChart].label === 'Region Stats' ||
						regionAnalysisTables[activeChart].label === 'Region Summary' ||
						regionAnalysisTables[activeChart].label === 'Territory Stats' ||
						regionAnalysisTables[activeChart].label === 'Territory Contribution' ||
						regionAnalysisTables[activeChart].label === 'Region Contribution' ||
						regionAnalysisTables[activeChart].label === 'Normalized Rx Trend' ||
						regionAnalysisTables[activeChart].label === 'Factored Rx Trend' ||
						regionAnalysisTables[activeChart].label === 'Direct Sales % of Total' ||
						regionAnalysisTables[activeChart].label === 'New SP Prescribers' ||
						regionAnalysisTables[activeChart].label === 'New SP Patients' ||
						regionAnalysisTables[activeChart].label === 'Prescribers' ||
						regionAnalysisTables[activeChart].label === 'Patients' ||
						regionAnalysisTables[activeChart].label === 'Calls'
					)}
					exportAsImg={(true)}
					addon={( // No-op div to disable user interaction
						<div
							style={{
								height: '94%',
								width: 'inherit',
								zIndex: 101,
								position: 'absolute',
								top: '6%', // Allows exports to function
								left: 0
							}}
						/>
					)}
				/>
			);
		}
	}

	const filtersList = (
		transitions.map(({ item, key, props }) => (
			item && (
				<animated.div key={key} style={props}>
					<div style={{ maxWidth: '98%' }}>
						<div style={{ minWidth: '100%', textAlign: 'right' }}>
							<Button type="text" onClick={onClickExportPPT}>Download All</Button>
						</div>
						<div className="filter-label-container" style={{ margin: '0px 0px 4px 0px' }}>
							<div className='filter-label filter-label-noreset'>Brand</div>
						</div>
						<Row gutter={[2, 16]}>
							<Dropdown
								trigger={['click']}
								overlay={brandMenu}
								placement="bottomRight"
							>
								<Button
									style={{ width: '100%' }}
									size={size}
								>
									<DownOutlined style={{
										float: 'right',
										marginTop: '4px',
										color: 'var(--brand)',
									}} />
									<div
										title={brands.length > 0 && brands[activeBrand].label}
										style={{
											paddingRight: '4%',
											paddingLeft: '0%',
											marginLeft: '4px',
											width: '94%',
											overflow: 'hidden',
											textAlign: 'left',
											fontWeight: 'bold',
											textDecorationColor: 'var(--brand)',
										}}
									>
										{brands.length > 0 && brands[activeBrand]}
									</div>
								</Button>
							</Dropdown>
						</Row>

						<div className="filter-label-container" style={{ margin: '0px 0px 4px 0px' }}>
							<div className='filter-label filter-label-noreset'>Chart</div>
						</div>
						<Row gutter={[2, 16]}>
							<Dropdown
								trigger={['click']}
								overlay={menu}
								placement="bottomRight"
							>
								<Button
									style={{ width: '100%', fontWeight: 'normal' }}
									size={size}
								>
									<DownOutlined style={{
										float: 'right',
										marginTop: '4px',
										color: 'var(--brand)',
									}} />
									<div
										title={regionAnalysisTables.length > 0 && regionAnalysisTables[activeChart].label}
										style={{
											paddingRight: '4%',
											paddingLeft: '0%',
											marginLeft: '6px',
											width: '90%',
											overflow: 'hidden',
											textAlign: 'left',
											fontWeight: 'bold',
											textDecorationColor: 'var(--brand)',
										}}
									>
										{regionAnalysisTables.length > 0 && regionAnalysisTables[activeChart].label}
									</div>
								</Button>
							</Dropdown>
						</Row>
						{(subFilterProps.value) && (
							<>
									<div className="filter-label-container">
										<div className='filter-label filter-label'>Chart Filters</div>
										<div className='filter-reset'>
											<Button type="link"
												onClick={() => {
													listboxSalesChannel[0].clear();
													listboxSalesTarget[0].clear();
													listboxSalesStrength[0].clear();
												}}
											>Reset</Button>
										</div>
									</div>
						<Row gutter={[2, 16]}>
							<Select {...subFilterProps} />
						</Row>
					</>
						)}
					{checkAccessToNation() && (
						<RegionFilter config={config} fieldName={filterFields.region} />
					)}
					{((activeTeam != 'HO3') || (checkAccessToNation() || checkAccessToRegion())) && (
						<TerritoryFilter config={config} fieldName={filterFields.territory} />
					)}
					<div className="filter-label-container" style={{ margin: '0px 0px 4px 0px' }}>
						<div className='filter-label filter-label-noreset'>Date</div>
					</div>
					<Row gutter={[2, 16]}>
						<DateSelectionButtons layout={activeTeam === 'HO3' ? 'MONJUVI' : ''} config={config} size={size} />
					</Row>
				</div>
				</animated.div >
			)))
	);

return (
	<Layout hasSider style={{ height: '100%', transform: 'scale(1.0)' }}>
		<div className='content-row'>
			<Sider
				width={310} collapsible theme="light"
				onCollapse={(collapsed) => {
					set_collapsed(collapsed);
				}}
				style={{ height: 'calc(100% - 4rem)', overflow: 'auto', padding: '10px' }}
			>
				{collapsed === false && (
					<>
						{filtersList}
					</>
				)}
			</Sider>
			<Layout style={{ backgroundColor: 'white', minWidth: '0' }} >
				{cardContent}
			</Layout>
		</div>
	</Layout>
);
}

export default RegionAnalysis;
