import React, { createContext, useState, useContext, useEffect } from "react";
import ConfigContext from "./config";
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";
import { useRecoilValue } from "recoil";
import { QSAppMetadataFamily } from "@trinity-incyte/recoil";
import MosaicContext from "./MosaicContext";
import { useHistory } from "react-router-dom";

declare var Mosaic: MosaicGlobal;

type HCOGroupData = {
    id: string
    name: string
    address: string
    city: string
    state: string
    zip: string
    capFlag: string
    accountType: string
    bmtFlag: string
    cartFlag: string
}

export type HCO360View = 'Search' | 'Overview' | 'HCPs' | 'Connections' | 'Reports'

export type HCO360State = {
    groupData: HCOGroupData,
    activeView: HCO360View
    brand: string
    groupId: string
    showGroup: boolean
}

export type HCO360Context = {
    state: HCO360State
    setActiveView: (view: HCO360View) => void
    setBrand: (brand: string) => void
    applyGroupId: (groupId: string) => void
    setShowGroup: (showGroup: boolean) => void
}
export const HCO360Context = createContext({} as HCO360Context);

export const HCO360State = (props) => {
	const Config = useContext(ConfigContext);
    const mosaicContext = useContext(MosaicContext);
	const config = Config.Qlik.Prof360;
	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;

    const [groupData, set_groupData] = useState<HCOGroupData>(null);
    const [activeView, setActiveView] = useState<HCO360View>(null);
    const [brand, setBrand] = useState<string>();
    const [groupId, setGroupId] = useState<string>(null);
    const [showGroup, setShowGroup] = useState(false);

    const history = useHistory()

    const onGroupIdSelected = (newGroupId:string) => {
        if (app.field("%GP_ID").stateCounts.qSelected == 0) {    
            setGroupId(null)
            history.replace("/HCO360")
        } else {
            const setAnalysis = `{1<[%GP_ID]={${newGroupId}}>}` // TODO - needs a friendly name

            // ${fields.get('Mosaic_HCP 360_PROF ID')?.id}
            // get the data for the HCO, but really HCG
            // form the query
            const queryDefinition = {
                qInfo: {qId: `hco360GroupInfo_${Date.now()}`},
                groupDetails: {
                    'id': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP_ID')?.id}] )` }, // probably unneccessary but may be handy
                    'name': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP Name')?.id}] )` },
                    'address': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP Address1')?.id}] )` },
                    'city': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP City')?.id}] )` },
                    'state': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP State')?.id}] )` },
                    'zip': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP ZIP')?.id}] )` },
                    'accountType': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP Segment')?.id}] )` },
                    'capFlag': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP CAP Account')?.id}] )` },
                    'bmtFlag': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP Has BMT Center')?.id}] )` },
                    'cartFlag': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCO 360_GP Has CART Center')?.id}] )` },
                },
            }
            app.createGenericObject(queryDefinition).then((data) => {
                // parse the return data
                let groupData = data?.layout?.groupDetails;
    
                // set the object here
                set_groupData(groupData);
    
                // set groupId
                setGroupId(newGroupId);
            });
        }
    }

    const applyGroupId = (newGroupId:string) => {
        // check to see if group id has changed, else return        
        if (newGroupId==groupId) return
        
        set_groupData(null)

        app.field("%GP_ID").selectValues([parseInt(newGroupId)])
        app.field("%GP_ID").onceOnDataListener(() => onGroupIdSelected(newGroupId))
        app.field("%GP_ID").getData()
    }

    useEffect(() => {
        mosaicContext.setFilterByTeam(false)
        return () => { 
            mosaicContext.setFilterByTeam(true)
            app.field("%GP_ID").clear()
        }
    }, [])

    useEffect(() => {
        return () => { 
            const hcp360App = Mosaic.Qlik.app[Config.Qlik.Prof360.ids.appId];
            if ( hcp360App ) {
                hcp360App.field( '%GP_ID' ).clearOther( true );
            }
        }
    }, [activeView])

    return (
        <HCO360Context.Provider value={{
            state: {
                groupData: groupData,
                activeView: activeView,
                brand: brand,
                groupId: groupId,
                showGroup: showGroup
            },
            setActiveView: setActiveView,
            setBrand: setBrand,
            applyGroupId: applyGroupId,
            setShowGroup: setShowGroup
        }}>
            {props.children}
        </HCO360Context.Provider>
    )
};

export default HCO360Context;